import {
  EXERCISE_CREATE_REQUEST,
  EXERCISE_CREATE_SUCCESS,
  EXERCISE_CREATE_FAILURE,
  TASK_DELETE_REQUEST,
  TASK_DELETE_SUCCESS,
  TASK_DELETE_FAILURE,
  EXERCISE_DELETE_REQUEST,
  EXERCISE_DELETE_SUCCESS,
  EXERCISE_DELETE_FAILURE,
  UPDATE_TASK_REQUEST,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAILURE,
  UPDATE_EXERCISE_REQUEST,
  UPDATE_EXERCISE_SUCCESS,
  UPDATE_EXERCISE_FAILURE,
  COPY_EXERCISE_REQUEST,
  COPY_EXERCISE_SUCCESS,
  COPY_EXERCISE_FAILURE,
  COPY_TASK_REQUEST,
  COPY_TASK_SUCCESS,
  COPY_TASK_FAILURE,
} from "./actionTypes";

import {
  FILL_IN_THE_GAP,
  MISSING_WORD,
  UNNECESSARY_WORD,
} from "../../constants/exerciseTypes";
import { EXERCISE_TITLES } from "../../config/exercises";

import { enqueueSnackbar } from "./notification";

import firebase from "../../tools/firebase";

const db = firebase.firestore();

const exerciseCreateSuccess = () => (dispatch) => {
  dispatch(
    enqueueSnackbar({
      message: "Exercise Created.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    })
  );
  return {
    type: EXERCISE_CREATE_SUCCESS,
  };
};

const exerciseCreateFailure = () => (dispatch) => {
  dispatch(
    enqueueSnackbar({
      message: "Error creating exercise.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    })
  );
  return {
    type: EXERCISE_CREATE_FAILURE,
  };
};

export const createExerciseFromErrant = (
  user,
  message = {},
  errantArray = []
) => async (dispatch) => {
  if (
    !user ||
    !message.text ||
    !message.correction ||
    errantArray.length === 0
  ) {
    dispatch(exerciseCreateFailure());
    return;
  }

  const errant = getErrant(errantArray);

  if (!errant) {
    dispatch(exerciseCreateFailure());
    return;
  }
  const exercise = createExercise(message, errant);
  const defaultTask = await getDefaultTask(user);

  if (!exercise || !defaultTask) {
    dispatch(exerciseCreateFailure());
    return;
  }

  await db
    .collection("platform")
    .doc("telegram")
    .collection("users")
    .doc(`${user}`)
    .collection("tasks")
    .doc(`${defaultTask}`)
    .collection(`exercises`)
    .add(exercise);

  dispatch(exerciseCreateSuccess());
};

const getErrant = (errantArray = []) => {
  for (const errant of errantArray) {
    if (errant.type && errant.type.charAt(0) === "R") {
      errant.type = FILL_IN_THE_GAP;
      return errant;
    }
    if (errant.type && errant.type.charAt(0) === "M") {
      errant.type = MISSING_WORD;
      return errant;
    }
    if (errant.type && errant.type.charAt(0) === "U") {
      errant.type = UNNECESSARY_WORD;
      return errant;
    }
  }
  return null;
};

const createExercise = (message = {}, errant = {}) => {
  switch (errant.type) {
    case FILL_IN_THE_GAP:
      return createReplaceExercise(message, errant);
    case MISSING_WORD:
      return createMissingExercise(message, errant);
    case UNNECESSARY_WORD:
      return createUnnecessaryWordExercise(message, errant);
    default:
      return null;
  }
};

const createReplaceExercise = (message = {}, errant = {}) => {
  const words = message.correction.split(" ");

  let firstText = [];
  let secondText = [];
  for (let i = 0; i < errant.c_start; i++) {
    firstText.push(words[i]);
  }

  for (let i = errant.c_end; i < words.length; i++) {
    secondText.push(words[i]);
  }

  const exercise = {
    type: FILL_IN_THE_GAP,
    firstText: firstText.join(" "),
    secondText: secondText.join(" "),
    hint: errant.o_str,
    solution: errant.c_str,
    title: EXERCISE_TITLES[FILL_IN_THE_GAP],
  };

  return exercise;
};

const createMissingExercise = (message = {}, errant = {}) => {
  const words = message.correction.split(" ");

  let firstText = [];
  let secondText = [];
  for (let i = 0; i < errant.c_start; i++) {
    firstText.push(words[i]);
  }

  for (let i = errant.c_end; i < words.length; i++) {
    secondText.push(words[i]);
  }

  const exercise = {
    type: MISSING_WORD,
    firstText: firstText.join(" "),
    secondText: secondText.join(" "),
    solution: errant.c_str,
    title: EXERCISE_TITLES[MISSING_WORD],
  };

  return exercise;
};

const createUnnecessaryWordExercise = (message = {}, errant = {}) => {
  const words = message.text.split(" ");

  let solution = [];
  for (let i = 0; i < errant.o_start; i++) {
    solution.push(words[i]);
  }

  for (let i = errant.o_end; i < words.length; i++) {
    solution.push(words[i]);
  }

  const exercise = {
    type: UNNECESSARY_WORD,
    original: message.text,
    solution: solution.join(" "),
    title: EXERCISE_TITLES[UNNECESSARY_WORD],
  };

  return exercise;
};

const getDefaultTask = async (user) => {
  const result = await db
    .collection("platform")
    .doc("telegram")
    .collection("users")
    .doc(`${user}`)
    .collection("tasks")
    .where("default", "==", true)
    .get();

  if (result.empty) {
    return await createDefaultTask(user);
  }

  let taskId = false;
  result.forEach((doc) => {
    taskId = doc.id;
  });

  return taskId || (await createDefaultTask(user));
};

const createDefaultTask = async (user) => {
  const result = await db
    .collection("platform")
    .doc("telegram")
    .collection("users")
    .doc(`${user}`)
    .collection("tasks")
    .add({
      default: true,
      title: "default",
    });

  return result.id;
};

const deleteTaskFailure = (error) => (dispatch) => {
  dispatch(
    enqueueSnackbar({
      message:
        error === 1 ? `Default task can´t be deleted` : "Error deleting task.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    })
  );
  return {
    type: TASK_DELETE_FAILURE,
  };
};

const deleteTaskSuccess = () => (dispatch) => {
  dispatch(
    enqueueSnackbar({
      message: "Task Deleted.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    })
  );
  return {
    type: TASK_DELETE_SUCCESS,
  };
};

export const deleteTask = (params = {}) => async (dispatch) => {
  const { userId, taskId, classId } = params;
  if ((!userId && !classId) || !taskId) {
    return dispatch(deleteTaskFailure());
  }

  const result = classId
    ? await db
        .collection("class")
        .doc(classId)
        .collection("taskLibrary")
        .doc(`${taskId}`)
        .get()
    : await db
        .collection("platform")
        .doc("telegram")
        .collection("users")
        .doc(`${userId}`)
        .collection("tasks")
        .doc(`${taskId}`)
        .get();

  if (result.empty) {
    return dispatch(deleteTaskFailure());
  }

  if (result.data().default) {
    return dispatch(deleteTaskFailure(1));
  }
  const deleteResult = classId
    ? await db
        .collection("class")
        .doc(classId)
        .collection("taskLibrary")
        .doc(`${taskId}`)
        .delete()
    : await db
        .collection("platform")
        .doc("telegram")
        .collection("users")
        .doc(`${userId}`)
        .collection("tasks")
        .doc(`${taskId}`)
        .delete();

  return dispatch(deleteTaskSuccess());
};

const deleteExerciseFailure = () => (dispatch) => {
  dispatch(
    enqueueSnackbar({
      message: "Error deleting exercise.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    })
  );
  return {
    type: EXERCISE_DELETE_FAILURE,
  };
};

const deleteExerciseSuccess = () => (dispatch) => {
  dispatch(
    enqueueSnackbar({
      message: "Exercise Deleted.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    })
  );
  return {
    type: EXERCISE_DELETE_SUCCESS,
  };
};

export const deleteExercise = (params = {}) => async (dispatch) => {
  const { userId, taskId, exerciseId, classId } = params;
  if (classId && exerciseId) {
    const result = await db
      .collection("class")
      .doc(`classId`)
      .collection("exerciseLibrary")
      .doc(`${exerciseId}`)
      .get();

    if (result.empty) {
      return dispatch(deleteExerciseFailure());
    }

    await db
      .collection("class")
      .doc(`${classId}`)
      .collection("exerciseLibrary")
      .doc(`${exerciseId}`)
      .delete();
  }
  if (!classId || !exerciseId) {
    if (!userId || !taskId || !exerciseId) {
      return dispatch(deleteExerciseFailure());
    }

    const result = await db
      .collection("platform")
      .doc("telegram")
      .collection("users")
      .doc(`${userId}`)
      .collection("tasks")
      .doc(`${taskId}`)
      .collection("exercises")
      .doc(`${exerciseId}`)
      .get();

    if (result.empty) {
      return dispatch(deleteExerciseFailure());
    }

    await db
      .collection("platform")
      .doc("telegram")
      .collection("users")
      .doc(`${userId}`)
      .collection("tasks")
      .doc(`${taskId}`)
      .collection("exercises")
      .doc(`${exerciseId}`)
      .delete();
  }

  return dispatch(deleteExerciseSuccess());
};

const updateTaskFailure = () => (dispatch) => {
  dispatch(
    enqueueSnackbar({
      message: "Error updating task.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    })
  );
  return {
    type: UPDATE_TASK_FAILURE,
  };
};

const updateTaskSuccess = () => (dispatch) => {
  dispatch(
    enqueueSnackbar({
      message: "Task updated.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    })
  );
  return {
    type: UPDATE_TASK_SUCCESS,
  };
};

export const updateTaskName = (params = {}) => async (dispatch) => {
  const { userId, taskId, taskName, classId } = params;
  try {
    if ((!userId && !classId) || !taskId || !taskName) {
      return dispatch(updateTaskFailure());
    }

    if (classId && taskId && taskName) {
      await db
        .collection("class")
        .doc(`${classId}`)
        .collection("taskLibrary")
        .doc(`${taskId}`)
        .set(
          {
            title: taskName,
          },
          {
            merge: true,
          }
        );
    }

    if (userId && taskId && taskName) {
      await db
        .collection("platform")
        .doc("telegram")
        .collection("users")
        .doc(`${userId}`)
        .collection("tasks")
        .doc(`${taskId}`)
        .set(
          {
            title: taskName,
          },
          {
            merge: true,
          }
        );
    }
    return dispatch(updateTaskSuccess());
  } catch (error) {
    return dispatch(updateTaskFailure());
  }
};

export const makeTaskDefault = (userId, taskId) => async (dispatch) => {
  if (!userId || !taskId) {
    return dispatch(updateTaskFailure());
  }
  try {
    const result = await db
      .collection("platform")
      .doc("telegram")
      .collection("users")
      .doc(`${userId}`)
      .collection("tasks")
      .where("default", "==", true)
      .get();

    result.forEach((doc) => {
      db.collection("platform")
        .doc("telegram")
        .collection("users")
        .doc(`${userId}`)
        .collection("tasks")
        .doc(doc.id)
        .set(
          {
            default: false,
          },
          {
            merge: true,
          }
        );
    });

    await db
      .collection("platform")
      .doc("telegram")
      .collection("users")
      .doc(`${userId}`)
      .collection("tasks")
      .doc(`${taskId}`)
      .set(
        {
          default: true,
        },
        {
          merge: true,
        }
      );

    return dispatch(updateTaskSuccess());
  } catch (error) {
    console.log(error);
    return dispatch(updateTaskFailure());
  }
};

const updateExerciseFailure = () => (dispatch) => {
  dispatch(
    enqueueSnackbar({
      message: "Error updating exercise.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    })
  );
  return {
    type: UPDATE_EXERCISE_FAILURE,
  };
};

const updateExerciseSuccess = () => (dispatch) => {
  dispatch(
    enqueueSnackbar({
      message: "Exercise updated.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    })
  );
  return {
    type: UPDATE_TASK_SUCCESS,
  };
};

export const updateExercise = (params = {}) => async (dispatch) => {
  const { userId, taskId, exerciseId, exerciseObject, classId } = params;
  if (classId && taskId) {
    await db
      .collection("class")
      .doc(`${classId}`)
      .collection("taskLibrary")
      .doc(`${taskId}`)
      .collection("exercises")
      .doc(`${exerciseId}`)
      .set(exerciseObject, {
        merge: true,
      });
  }
  if (classId && !taskId) {
    await db
      .collection("class")
      .doc(`${classId}`)
      .collection("exerciseLibrary")
      .doc(`${exerciseId}`)
      .set(exerciseObject, {
        merge: true,
      });
  }

  if (!classId) {
    if (!userId || !taskId || !exerciseId) {
      console.log(userId, taskId, exerciseId);
      return dispatch(updateExerciseFailure());
    }

    await db
      .collection("platform")
      .doc("telegram")
      .collection("users")
      .doc(`${userId}`)
      .collection("tasks")
      .doc(`${taskId}`)
      .collection("exercises")
      .doc(`${exerciseId}`)
      .set(exerciseObject, {
        merge: true,
      });
  }
  return dispatch(updateExerciseSuccess());
};

export const newExercise = (params = {}) => async (dispatch) => {
  const { userId, taskId, exerciseObject, classId } = params;
  if (classId && !taskId) {
    await db
      .collection("class")
      .doc(`${classId}`)
      .collection("exerciseLibrary")
      .add(exerciseObject);
  }
  if (classId && taskId) {
    await db
      .collection("class")
      .doc(`${classId}`)
      .collection("taskLibrary")
      .doc(`${taskId}`)
      .collection("exercises")
      .add(exerciseObject);
  }
  if (!classId) {
    if (!userId || !taskId) {
      return dispatch(exerciseCreateFailure());
    }

    await db
      .collection("platform")
      .doc("telegram")
      .collection("users")
      .doc(`${userId}`)
      .collection("tasks")
      .doc(`${taskId}`)
      .collection("exercises")
      .add(exerciseObject);
  }
  return dispatch(exerciseCreateSuccess());
};

const exerciseCopySuccess = () => (dispatch) => {
  dispatch(
    enqueueSnackbar({
      message: "Exercise Copied.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    })
  );
  return {
    type: COPY_EXERCISE_SUCCESS,
  };
};

const exerciseCopyFailure = () => (dispatch) => {
  dispatch(
    enqueueSnackbar({
      message: "Error copying exercise.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    })
  );
  return {
    type: COPY_EXERCISE_FAILURE,
  };
};

export const copyExercise = (params = {}) => async (dispatch) => {
  const { userId, exerciseObject, exerciseId } = params;
  if (!userId || !exerciseObject || !exerciseId) {
    return dispatch(exerciseCopyFailure());
  }

  if (!exerciseObject.originalExercise) {
    exerciseObject.originalExercise = exerciseId;
  }

  delete exerciseObject.solved;
  delete exerciseObject.answers;
  delete exerciseObject.id;

  const defaultTask = await getDefaultTask(userId);

  if (!defaultTask) return dispatch(exerciseCopyFailure());

  await db
    .collection("platform")
    .doc("telegram")
    .collection("users")
    .doc(`${userId}`)
    .collection("tasks")
    .doc(`${defaultTask}`)
    .collection(`exercises`)
    .add(exerciseObject);

  dispatch(exerciseCopySuccess());
};

const taskCopySuccess = () => (dispatch) => {
  dispatch(
    enqueueSnackbar({
      message: "Task Copied.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    })
  );
  return {
    type: COPY_TASK_SUCCESS,
  };
};

const taskCopyFailure = () => (dispatch) => {
  dispatch(
    enqueueSnackbar({
      message: "Error copying task.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    })
  );
  return {
    type: COPY_TASK_FAILURE,
  };
};

export const copyTask = (params = {}) => async (dispatch) => {
  const { userId, taskObject, classId, taskId, destinataries } = params;
  if ((!userId && !classId) || !taskObject || !taskId || !destinataries) {
    return dispatch(taskCopyFailure());
  }

  if (!taskObject.originalTask) {
    taskObject.originalTask = taskId;
  }

  const exercisesResult = classId
    ? await db
        .collection("class")
        .doc(classId)
        .collection("taskLibrary")
        .doc(`${taskId}`)
        .collection("exercises")
        .get()
    : await db
        .collection("platform")
        .doc("telegram")
        .collection("users")
        .doc(`${userId}`)
        .collection("tasks")
        .doc(`${taskId}`)
        .collection("exercises")
        .get();

  for (const destId of destinataries) {
    const destTaskId = createTask(taskObject, destId);
    if (!exercisesResult.empty) {
      exercisesResult.forEach((doc) => {
        let exerciseObject = doc.data();

        if (!exerciseObject.originalExercise) {
          exerciseObject.originalExercise = doc.id;
        }

        delete exerciseObject.solved;
        delete exerciseObject.answers;
        delete exerciseObject.id;

        db.collection("platform")
          .doc("telegram")
          .collection("users")
          .doc(`${destId}`)
          .collection("tasks")
          .doc(`${destTaskId}`)
          .collection(`exercises`)
          .add(exerciseObject);
      });
    }
  }

  dispatch(taskCopySuccess());
};

const createTask = async (taskObject, userId) => {
  const result = await db
    .collection("platform")
    .doc("telegram")
    .collection("users")
    .doc(`${userId}`)
    .collection("tasks")
    .add(taskObject);

  return result.id;
};

export const addExercisesToTasks = (params = {}) => async (dispatch) => {
  const { exercises, tasks, classId } = params;

  if (
    !exercises ||
    !tasks ||
    exercises.length === 0 ||
    tasks.length === 0 ||
    !classId
  ) {
    return dispatch(exerciseCopyFailure());
  }

  for (const exerciseId of exercises) {
    const response = await db
      .collection("class")
      .doc(`${classId}`)
      .collection("exerciseLibrary")
      .doc(`${exerciseId}`)
      .get();
    if (!response.empty) {
      let exerciseObject = response.data();

      if (!exerciseObject.originalExercise) {
        exerciseObject.originalExercise = response.id;
      }

      delete exerciseObject.solved;
      delete exerciseObject.answers;
      delete exerciseObject.id;
      for (const taskId of tasks) {
        await db
          .collection("class")
          .doc(classId)
          .collection("taskLibrary")
          .doc(`${taskId}`)
          .collection("exercises")
          .add(exerciseObject);
      }
    }
  }
  dispatch(exerciseCopySuccess());
};
