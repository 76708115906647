import React from "react";
import Header from "../Header";
import { Route, Redirect } from "react-router-dom";
import { routes } from "../../routes";

const ProtectedRoute = ({
  component: Component,
  isAuthenticated,
  isVerifying,
  logo,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isVerifying ? (
        <div />
      ) : isAuthenticated ? (
        <>
          <Header logo={logo} {...props} />
          <Component {...props} />
        </>
      ) : (
        <Redirect
          to={{
            pathname: routes.LOGIN.path,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default ProtectedRoute;
