import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => {
  const paletteType = theme.palette.type;
  return {
    container: {
      color: "#e65820",
    },
    bubbleContainer: {
      width: "100%",
    },
    bubble: {
      border: "1px solid black",
      borderRadius: "10px",
      margin: "5px",
      padding: "10px",
      display: "inline-block",
      backgroundColor: paletteType === "dark" ? "black" : "transparent",
      borderColor: "black",
    },
  };
});

const SpeechBubble = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.bubbleContainer}>
        <div className={classes.bubble}>
          <div className={classes.button}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default SpeechBubble;
