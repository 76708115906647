import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";
import {
  LEVEL_PROGRESS_ACTIVE,
  LEVEL_PROGRESS_BACKGROUND,
} from "../../constants/colors";

class CogLinearProgress extends Component {
  render() {
    const { classes } = this.props;
    return (
      <LinearProgress
        {...this.props}
        classes={{
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary,
          root: classes.root,
        }}
      />
    );
  }
}

const styles = (props) => ({
  colorPrimary: {
    backgroundColor: LEVEL_PROGRESS_BACKGROUND,
  },
  barColorPrimary: {
    backgroundColor: LEVEL_PROGRESS_ACTIVE,
  },
  root: {
    height: "17px",
  },
});

export default withStyles(styles)(CogLinearProgress);
