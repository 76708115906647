import React, { useState, useEffect } from "react";
import firebase from "../../tools/firebase";
import {
  Grid,
  Hidden,
  List,
  ListItem,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Checkbox,
  ListItemSecondaryAction,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { connect, useDispatch } from "react-redux";

import UsersDialog from "../UsersDialog";
import StatsTable from "../StatsTable";
import StatsWordChart from "../StatsWordChart";

const db = firebase.firestore();

function Stats(props) {
  const { user } = props.auth;

  const [selectedUsers, setSelectedUsers] = useState(
    user.admin ? [] : [user.id]
  );
  const [modalOpen, setModalOpen] = useState(user.admin ? true : false);
  const [stats, setStats] = useState({});
  const numUsers = Object.keys(stats).length;
  const experiencePoints = Object.keys(stats)
    .map((userId) => stats[userId]?.experience?.points || 0)
    .reduce((a, b) => a + b, 0);
  const totalMessages = Object.keys(stats)
    .map((userId) => stats[userId]?.messages?.total || 0)
    .reduce((a, b) => a + b, 0);
  const totalWords = Object.keys(stats)
    .map((userId) => stats[userId]?.vocabulary?.wordTotal || 0)
    .reduce((a, b) => a + b, 0);
  const uniqueWords =
    numUsers > 1
      ? [
          ...new Set(
            Object.keys(stats)
              .map((userId) => stats[userId]?.vocabulary?.words || [])
              .flat()
          ),
        ].length
      : Object.keys(stats).map(
          (userId) => stats[userId]?.vocabulary?.wordUnique || 0
        )[0];

  const totalWordsData = () => {
    const dates = [
      ...new Set(
        Object.keys(stats)
          .map((userId) => stats[userId]?.vocabulary?.words || [])
          .flat()
      ),
    ];
  };

  useEffect(() => {
    if (selectedUsers.length > 0) {
      const unsuscribe = selectedUsers.map((userId) => {
        return db
          .collection("platform")
          .doc("telegram")
          .collection("users")
          .doc(`${userId}`)
          .collection("stats")
          .onSnapshot(function (querySnapshot) {
            let userStats = {};
            querySnapshot.forEach(function (doc) {
              userStats[doc.id] = doc.data();
            });
            setStats((prevStats) => ({
              ...prevStats,
              [userId]: userStats,
            }));
          });
      });

      return () => {
        for (const unsuscribeFunction of unsuscribe) {
          unsuscribeFunction();
        }
      };
    }
  }, [selectedUsers]);

  const handleCloseDialog = (checkedUsers) => {
    setSelectedUsers(
      Object.keys(checkedUsers).filter(
        (userId) => checkedUsers[userId] === true
      )
    );
    setModalOpen(false);
  };
  console.log(totalWordsData);
  return (
    <>
      {user.admin && (
        <UsersDialog open={modalOpen} onClose={handleCloseDialog} />
      )}
      {Object.keys(stats).length === 0 && selectedUsers.length > 0 && (
        <Typography>Loading</Typography>
      )}
      {Object.keys(stats).length > 0 && (
        <>
          <StatsTable
            data={[
              { name: "User Number", info: numUsers },
              { name: "Experience Points", info: experiencePoints },
              { name: "Messages", info: totalMessages },
              { name: "Total Words", info: totalWords },
              { name: "Unique Words", info: uniqueWords },
            ]}
          />

          <StatsWordChart selectedUsers={selectedUsers} />
        </>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Stats);
