import Axios from "axios";

const NLP_URL = "https://api.writify.io/gec/";

export async function getErrant(text = {}) {
  if (!text.original || !text.correction) {
    return false;
  }
  try {
    const response = await Axios.get(`${NLP_URL}errors/`, {
      params: {
        orig: text.original,
        corr: text.correction,
      },
    });
    return response.data;
  } catch (error) {
    return false;
  }
}
