import {
  EXERCISE_CREATE_REQUEST,
  EXERCISE_CREATE_SUCCESS,
  EXERCISE_CREATE_FAILURE,
} from "../actions/actionTypes";

const INITIAL_STATE = {};

export default function exerciseReducer(state = INITIAL_STATE, action) {
  switch (action) {
    case EXERCISE_CREATE_REQUEST:
      return { ...state };

    case EXERCISE_CREATE_SUCCESS:
      return {
        ...state,
        type: "success",
        message: "Exercise created",
      };

    case EXERCISE_CREATE_FAILURE:
      return {
        ...state,
        type: "success",
        message: "Exercise created",
      };

    default:
      return state;
  }
}
