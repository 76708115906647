import React, { useEffect, useState } from "react";
import firebase from "../../tools/firebase";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";

import { Box, Typography } from "@material-ui/core";

import {
  ENGAGEMENT_DARK,
  ENGAGEMENT_LIGHT,
  YELLOW,
  PROFILE,
  EFFORT_BACKGROUND,
  WORD_CHART_BACKGROUND,
  WORD_CHART_BAR,
} from "../../constants/colors";

import { makeStyles } from "@material-ui/core/styles";

const db = firebase.firestore();

const useStyles = makeStyles((theme) => {
  const paletteType = theme.palette.type;

  return {
    chartBox: {
      backgroundColor:
        paletteType === "dark" ? WORD_CHART_BACKGROUND : WORD_CHART_BACKGROUND,
      borderRadius: "6px",
      paddingTop: "15px",
    },
  };
});

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: ENGAGEMENT_DARK,
          padding: "3px",
          borderRadius: "6px",
          color: "white",
        }}
      >
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

function StatsWordChart(props) {
  const { selectedUsers } = props;
  const [stats, setStats] = useState({});
  const data = Object.keys(stats).map((date) => stats[date]);
  const classes = useStyles();

  useEffect(() => {
    if (selectedUsers.length > 0) {
      const unsuscribe = selectedUsers.map((userId) => {
        return db
          .collection("platform")
          .doc("telegram")
          .collection("users")
          .doc(`${userId}`)
          .collection("stats")
          .doc("messages")
          .collection("date")
          .onSnapshot(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              setStats((prevStats) => ({
                ...prevStats,
                [doc.id]: {
                  ...prevStats[doc.id],
                  name: [doc.id],
                  [userId]: doc.data().total || 0,
                },
              }));
            });
          });
      });

      return () => {
        for (const unsuscribeFunction of unsuscribe) {
          unsuscribeFunction();
        }
      };
    }
  }, [selectedUsers]);

  return (
    <>
      {selectedUsers.length > 0 && (
        <Box classes={{ root: classes.chartBox }}>
          {selectedUsers.length > 1 && (
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {selectedUsers &&
                  selectedUsers.length > 0 &&
                  selectedUsers.map((user) => (
                    <Line key={user} type="monotone" dataKey={user} />
                  ))}
              </LineChart>
            </ResponsiveContainer>
          )}

          {selectedUsers.length === 1 && (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                height={300}
                width={400}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} cursor={false} />
                {selectedUsers &&
                  selectedUsers.length > 0 &&
                  selectedUsers.map((user) => (
                    <Bar
                      key={user}
                      type="monotone"
                      fill={WORD_CHART_BAR}
                      dataKey={user}
                    />
                  ))}
              </BarChart>
            </ResponsiveContainer>
          )}
        </Box>
      )}
    </>
  );
}

export default StatsWordChart;
