import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import BarChartIcon from "@material-ui/icons/BarChart";
import firebase from "../../tools/firebase";
import { levels } from "../../config/levels";
import TelegramIcon from "@material-ui/icons/Telegram";
import CreateIcon from "@material-ui/icons/Create";
import DashboardIcon from "@material-ui/icons/Dashboard";
import {
  ENGAGEMENT_DARK,
  ENGAGEMENT_LIGHT,
  YELLOW,
  PROFILE,
} from "../../constants/colors";
import Engagement from "../Engagement";
import Logo from "../CustomUI/Logo";
import cogIcon from "../../static/cog-dark-on-orange-bg.png";
import { routes } from "../../routes";
import { withPropsOnChange } from "recompose";
const db = firebase.firestore();

const useStyles = makeStyles((theme) => {
  const paletteType = theme.palette.type;

  return {
    mainScreen: {
      paddingTop: "20px",
    },
    infoBox: {
      backgroundColor: paletteType === "dark" ? PROFILE : PROFILE,
      borderRadius: "6px",
      color: "white",
    },
  };
});

function Home(props) {
  const classes = useStyles();
  const theme = useTheme();

  const handleTelegramClick = () => {
    window.open("https://t.me/cognateAI_bot", "_blank");
  };

  const handleDashboardClick = () => {
    props.history.push(routes.DASHBOARD.path);
  };

  const handleExercisesClick = () => {
    props.history.push(routes.TASK_LIST.path);
  };

  return (
    <>
      <Container maxWidth="sm" className={classes.mainScreen}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
        >
          <Logo letters slogan="h6" maxWidth="180px" width={2 / 5} />
          <Box
            maxWidth="xs"
            mt="20px"
            mx="auto"
            py="10px"
            px="20px"
            width="100%"
            classes={{ root: classes.infoBox }}
          >
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="center"
            >
              <Box width="100%">
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <img width="150px" src={cogIcon} alt="Cog" />
                  <Typography
                    variant="body2"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    This is Cog
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    She's your personal language trainer
                  </Typography>
                </Grid>
              </Box>
              <Box mt="15px">
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Box width="100%" maxWidth="sm" onClick={handleTelegramClick}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Typography variant="body1">
                        Chat with Cog on Telegram
                      </Typography>
                      <Box style={{ marginLeft: "10px" }}>
                        <TelegramIcon fontSize="large" />
                      </Box>
                    </Grid>
                  </Box>
                  <Box
                    width="100%"
                    maxWidth="md"
                    onClick={handleExercisesClick}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Typography variant="body1">
                        Go to your personalised lessons
                      </Typography>
                      <Box style={{ marginLeft: "10px" }}>
                        <CreateIcon fontSize="large" />
                      </Box>
                    </Grid>
                  </Box>
                  <Box width="100%" onClick={handleDashboardClick}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Typography variant="body1">
                        Check your progress
                      </Typography>
                      <Box style={{ marginLeft: "10px" }}>
                        <DashboardIcon fontSize="large" />
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Box>
          <Engagement />
        </Grid>
      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Home);
