import React from "react";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SkipPrevious, SkipNext } from "@material-ui/icons";
import purple from "@material-ui/core/colors/purple";

const useStyles = makeStyles((theme) => {
  const paletteType = theme.palette.type;
  const yellowButton = "#F2C94C";
  const background = paletteType === "dark" ? "#303030" : purple[900];

  return {
    stickToBottom: {
      width: "100%",
      position: "fixed",
      bottom: 0,
      backgroundColor: background,
    },
    actionItemStyles: {
      color: yellowButton,
    },
  };
});

function Footer(props) {
  const classes = useStyles();

  return (
    <BottomNavigation showLabels className={classes.stickToBottom}>
      {props.step === 0 && <BottomNavigationAction disabled={true} />}
      {props.step > 0 && (
        <BottomNavigationAction
          label="Back"
          icon={<SkipPrevious />}
          onClick={() => props.prevStep()}
          classes={{
            root: classes.actionItemStyles,
          }}
        />
      )}
      {props.step + 1 < props.stepNum && (
        <BottomNavigationAction
          label="Next"
          icon={<SkipNext />}
          onClick={() => props.nextStep()}
          classes={{
            root: classes.actionItemStyles,
          }}
        />
      )}
      {props.step + 1 === props.stepNum && (
        <BottomNavigationAction disabled={true} />
      )}
    </BottomNavigation>
  );
}

export default Footer;
