exports.routes = {
  LANDING: {
    path: "/",
  },
  EXERCISES: {
    path: "/exercises",
  },
  DASHBOARD: {
    path: "/dashboard",
  },
  ADMIN: {
    path: "/admin",
  },
  MESSAGES_ADMIN: {
    path: "/messagesAdmin",
  },
  LOGIN: {
    path: "/login",
  },
  SIGNUP: {
    path: "/signup",
  },
  USER_EXERCISES_ADMIN: {
    path: "/userExercisesAdmin",
  },
  EXERCISES_ADMIN: {
    path: "/exercisesAdmin",
  },
  STATS: {
    path: "/stats",
  },
  TASK_LIST: {
    path: "/tasks",
  },
  HOME: {
    path: "/home",
  },
};
