import React from "react";
import { Route } from "react-router-dom";
import { Switch } from "react-router";
import { connect } from "react-redux";
import MUICookieConsent from "material-ui-cookie-consent";

import Landing from "../Landing";
import Exercises from "../Exercises";
import Dashboard from "../Dashboard";
import Admin from "../Admin";
import UserExercisesAdmin from "../UserExercisesAdmin";
import MessagesAdmin from "../MessagesAdmin";
import Login from "../Login";
import SignUp from "../SignUp";
import Notifier from "../Notifier";

import { routes } from "../../routes";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from "notistack";
import ProtectedRoute from "../Routes/ProtectedRoute";
import ExercisesAdmin from "../ExercisesAdmin";
import Stats from "../Stats";
import Home from "../Home";

import {
  BACKGROUND_DARK,
  BACKGROUND_LIGHT,
  ENGAGEMENT_DARK,
  ENGAGEMENT_LIGHT,
  SECONDARY_COLOR,
  PRIMARY_COLOR,
} from "../../constants/colors";
import TaskList from "../TaskList/TaskList";

function App(props) {
  const { isAuthenticated, isVerifying } = props;

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  //const prefersDarkMode = false; // force light
  //const prefersDarkMode = true; // force dark

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: { main: PRIMARY_COLOR },
          secondary: { main: SECONDARY_COLOR },
          type: prefersDarkMode ? "dark" : "light",
          background: {
            default: prefersDarkMode ? BACKGROUND_DARK : BACKGROUND_LIGHT,
            paper: prefersDarkMode ? ENGAGEMENT_DARK : ENGAGEMENT_LIGHT,
          },
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <Notifier />
        <Switch>
          <ProtectedRoute
            exact
            path={routes.ADMIN.path}
            component={Admin}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <ProtectedRoute
            exact
            path={routes.MESSAGES_ADMIN.path}
            component={MessagesAdmin}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <ProtectedRoute
            exact
            path={routes.USER_EXERCISES_ADMIN.path}
            component={UserExercisesAdmin}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <ProtectedRoute
            exact
            path={routes.LANDING.path}
            component={Landing}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <ProtectedRoute
            exact
            path={`${routes.EXERCISES.path}/:taskId?`}
            component={Exercises}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <ProtectedRoute
            exact
            path={routes.DASHBOARD.path}
            component={Dashboard}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <ProtectedRoute
            exact
            path={routes.EXERCISES_ADMIN.path}
            component={ExercisesAdmin}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <ProtectedRoute
            exact
            path={routes.STATS.path}
            component={Stats}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <ProtectedRoute
            exact
            path={routes.TASK_LIST.path}
            component={TaskList}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <ProtectedRoute
            exact
            path={routes.HOME.path}
            component={Home}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
            logo={false}
          />
          <Route exact path={routes.LOGIN.path} component={Login} />
          <Route exact path={`${routes.SIGNUP.path}/:id?`} component={SignUp} />
        </Switch>
      </SnackbarProvider>
      <MUICookieConsent
        cookieName="writifyCookieConsent"
        componentType="Dialog" // default value is Snackbar
        message="This site uses cookies.... bla bla..."
      />
    </ThemeProvider>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
  };
}

export default connect(mapStateToProps)(App);
