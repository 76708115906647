import React from "react";
import {
  Grid,
  Button,
  Box,
  Paper,
  Card,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import { connect } from "react-redux";
import { routes } from "../../routes";

function Admin(props) {
  return (
    <>
      {props.auth.user.admin !== true && (
        <Paper>You don't have permission to see this page</Paper>
      )}
      {props.auth.user.admin === true && (
        <Grid>
          <Grid item xs={6} style={{ margin: "auto", marginTop: "20px" }}>
            <Card variant="outlined">
              <CardHeader title="Admin" style={{ textAlign: "center" }} />
              <CardContent style={{ textAlign: "center" }}>
                <Box>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      props.history.push(`${routes.MESSAGES_ADMIN.path}`)
                    }
                  >
                    Messages
                  </Button>
                </Box>
                <Box mt={3}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      props.history.push(`${routes.EXERCISES_ADMIN.path}`)
                    }
                  >
                    Exercises Library
                  </Button>
                </Box>
                <Box mt={3}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      props.history.push(`${routes.USER_EXERCISES_ADMIN.path}`)
                    }
                  >
                    User Exercises
                  </Button>
                </Box>
                <Box mt={3}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => props.history.push(`${routes.STATS.path}`)}
                  >
                    Stats
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Admin);
