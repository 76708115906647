import React, { useState, useEffect } from "react";
import { Box, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

function MissingWord(props) {
  const classes = useStyles();

  const [inputWidth, setInputWidth] = useState("3ch");

  useEffect(() => {
    setInputWidth(
      props.answer.length > 0
        ? `${props.answer.length}ch`
        : `${props.step.solution.length}ch`
    );
  }, [props.answer, props.step]);

  return (
    <>
      <Box
        style={{
          lineHeight: "2em",
          fontSize: "1rem",
          textAlign: "center",
        }}
      >
        <Box style={{ verticalAlign: "bottom" }} component="span">
          {`${props.step.firstText} `}
        </Box>
        {props.correct === true && (
          <Box style={{ color: "green" }} component="span">
            {props.step.answer}
          </Box>
        )}
        {!props.correct && (
          <TextField
            style={{ width: inputWidth, textAlign: "center", minWidth: "30px" }}
            error
            id="standard-secondary"
            color="secondary"
            value={props.answer}
            onChange={props.manageAnswerChange}
          />
        )}
        {props.step.secondText && (
          <>
            <Box style={{ verticalAlign: "bottom" }} component="span">
              {` ${props.step.secondText}`}
            </Box>
          </>
        )}
      </Box>
      <Box mx="auto" mt="20px" style={{ textAlign: "center" }}>
        {!props.correct && (
          <Button
            variant="contained"
            color="primary"
            onClick={props.checkCorrect}
          >
            Submit
          </Button>
        )}

        {props.correct === true && (
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "green" }}
            onClick={props.nextStep}
          >
            Next
          </Button>
        )}
      </Box>
    </>
  );
}

export default MissingWord;
