import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Avatar,
  Box,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import { connect } from "react-redux";
import { logoutUser } from "../../stores/actions/auth";
import { routes } from "../../routes";
import firebase from "../../tools/firebase";
import Logo from "../CustomUI/Logo";
import HomeIcon from "@material-ui/icons/Home";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CreateIcon from "@material-ui/icons/Create";
import ChatIcon from "@material-ui/icons/Chat";
import GroupIcon from "@material-ui/icons/Group";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";

import {
  BACKGROUND_DARK,
  BACKGROUND_LIGHT,
  PROFILE,
} from "../../constants/colors";
const db = firebase.firestore();

const useStyles = makeStyles((theme) => {
  const paletteType = theme.palette.type;
  const background =
    paletteType === "dark" ? BACKGROUND_DARK : BACKGROUND_LIGHT;
  return {
    appBar: {
      backgroundColor: background,
    },
    iconButtonLabel: {
      display: "flex",
      flexDirection: "column",
    },
    buttons: {
      backgroundColor: PROFILE,
      color: background,
    },
    experiencePoints: {
      color: paletteType === "dark" ? PROFILE : "black",
      fontSize: "0.5em",
    },
    menuButton: {
      color: PROFILE,
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: 240,
      backgroundColor: background,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  };
});

function Header(props) {
  const classes = useStyles();
  const { auth, logo } = props;
  const { user } = auth;
  const [experiencePoints, setExperiencePoints] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const logOut = () => {
    const { dispatch } = props;
    dispatch(logoutUser());
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExercises = () => {
    props.history.push(`${routes.TASK_LIST.path}`);
    handleClose();
  };

  const handleHome = () => {
    props.history.push(`${routes.HOME.path}`);
    handleClose();
  };

  const handleDashboard = () => {
    props.history.push(`${routes.DASHBOARD.path}`);
    handleClose();
  };

  const handleMessages = () => {
    props.history.push(`${routes.MESSAGES_ADMIN.path}`);
    handleClose();
  };

  const handleUserExercises = () => {
    props.history.push(`${routes.USER_EXERCISES_ADMIN.path}`);
    handleClose();
  };

  const handleExercisesLibrary = () => {
    props.history.push(`${routes.EXERCISES_ADMIN.path}`);
    handleClose();
  };

  const handleStats = () => {
    props.history.push(`${routes.STATS.path}`);
    handleClose();
  };

  useEffect(() => {
    const unsubscribe = db
      .collection("platform")
      .doc("telegram")
      .collection("users")
      .doc(`${user.id}`)
      .collection("stats")
      .doc(`experience`)
      .onSnapshot(function (doc) {
        const exp = doc.data() ? doc.data().points || 0 : 0;
        setExperiencePoints(exp);
      });
    return unsubscribe;
  }, [user]);

  const firstName = user.first_name || "";
  const lastName = user.last_name || "";

  console.log(logo);

  return (
    <>
      <AppBar position="static" classes={{ root: classes.appBar }}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
          <Box m="auto" p="10px">
            {logo !== false && <Logo width="50px" />}
          </Box>
          <Box>
            <IconButton
              classes={{ label: classes.iconButtonLabel }}
              onClick={() => props.history.push(`${routes.DASHBOARD.path}`)}
            >
              <Avatar
                classes={{ root: classes.buttons }}
                alt={`${firstName} ${lastName}`}
                className={classes.large}
              >
                {`${firstName.charAt(0)}${lastName.charAt(0)}`}
              </Avatar>
              <Box classes={{ root: classes.experiencePoints }}>
                {experiencePoints} XP
              </Box>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <div>
          <Box
            display="flex"
            m="auto"
            p="10px"
            width="100%"
            justifyContent="center"
          >
            <Logo width="50px" />
          </Box>
          <Divider />
          <List>
            <ListItem button onClick={handleHome}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText>Home</ListItemText>
            </ListItem>
            <ListItem button onClick={handleDashboard}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText>Dashboard</ListItemText>
            </ListItem>
            <ListItem button onClick={handleExercises}>
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText>Exercises</ListItemText>
            </ListItem>
            {user && user.admin && (
              <>
                <Divider />
                <ListItem button onClick={handleMessages}>
                  <ListItemIcon>
                    <ChatIcon />
                  </ListItemIcon>
                  <ListItemText>Messages</ListItemText>
                </ListItem>
                <ListItem button onClick={handleUserExercises}>
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText>User Exercises</ListItemText>
                </ListItem>
                <ListItem button onClick={handleExercisesLibrary}>
                  <ListItemIcon>
                    <LocalLibraryIcon />
                  </ListItemIcon>
                  <ListItemText>Exercises Library</ListItemText>
                </ListItem>
                <ListItem button onClick={handleStats}>
                  <ListItemIcon>
                    <EqualizerIcon />
                  </ListItemIcon>
                  <ListItemText>Stats</ListItemText>
                </ListItem>
              </>
            )}
            <Divider />
            <ListItem button onClick={() => logOut()}>
              <ListItemIcon>
                <PowerSettingsNewIcon />
              </ListItemIcon>
              <ListItemText>Sign Out</ListItemText>
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Header);
