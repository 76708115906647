import React from "react";
import { ListItem, TextField } from "@material-ui/core";

const MissingWordExercise = (props) => {
  const { exerciseObject, handleExerciseChange } = props;

  return (
    <>
      <ListItem>
        <TextField
          disabled={exerciseObject.solved}
          required
          name="title"
          label="Title"
          onChange={handleExerciseChange}
          value={exerciseObject.title}
          variant="outlined"
        />
      </ListItem>
      <ListItem>
        <TextField
          disabled={exerciseObject.solved}
          required
          name="firstText"
          label="First text"
          onChange={handleExerciseChange}
          value={exerciseObject.firstText}
          variant="outlined"
        />
      </ListItem>
      <ListItem>
        <TextField
          disabled={exerciseObject.solved}
          required
          name="solution"
          label="Missing Word"
          onChange={handleExerciseChange}
          value={exerciseObject.solution}
          variant="outlined"
        />
      </ListItem>
      <ListItem>
        <TextField
          disabled={exerciseObject.solved}
          required
          name="secondText"
          label="Second text"
          onChange={handleExerciseChange}
          value={exerciseObject.secondText}
          variant="outlined"
        />
      </ListItem>
    </>
  );
};

export default MissingWordExercise;
