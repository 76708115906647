import React, { useEffect, useState } from "react";
import firebase from "../../tools/firebase";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";

import { Box, Typography } from "@material-ui/core";

import { EXERCISES_CHART_BACKGROUND } from "../../constants/colors";

import { makeStyles } from "@material-ui/core/styles";

const db = firebase.firestore();

const useStyles = makeStyles((theme) => {
  const paletteType = theme.palette.type;

  return {
    chartBox: {
      backgroundColor:
        paletteType === "dark"
          ? EXERCISES_CHART_BACKGROUND
          : EXERCISES_CHART_BACKGROUND,
      borderRadius: "6px",
      paddingTop: "15px",
    },
  };
});

function StatsExercisesChart(props) {
  const { selectedUsers } = props;
  const [stats, setStats] = useState({});
  const data = Object.keys(stats).map((date) => stats[date]);
  const classes = useStyles();

  useEffect(() => {
    if (selectedUsers.length > 0) {
      const unsuscribe = selectedUsers.map((userId) => {
        return db
          .collection("platform")
          .doc("telegram")
          .collection("users")
          .doc(`${userId}`)
          .collection("stats")
          .doc("exercises")
          .collection("date")
          .onSnapshot(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              setStats((prevStats) => ({
                ...prevStats,
                [doc.id]: {
                  ...prevStats[doc.id],
                  name: [doc.id],
                  [userId]: doc.data().solved || 0,
                },
              }));
            });
          });
      });

      return () => {
        for (const unsuscribeFunction of unsuscribe) {
          unsuscribeFunction();
        }
      };
    }
  }, [selectedUsers]);

  return (
    <>
      {selectedUsers.length > 0 && (
        <Box classes={{ root: classes.chartBox }}>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              {selectedUsers.length > 1 && <Legend />}
              {selectedUsers &&
                selectedUsers.length > 0 &&
                selectedUsers.map((user) => (
                  <Line key={user} type="monotone" dataKey={user} />
                ))}
            </LineChart>
          </ResponsiveContainer>
        </Box>
      )}
    </>
  );
}

export default StatsExercisesChart;
