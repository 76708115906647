import React from "react";
import { useTheme } from "@material-ui/core/styles";
import logoWhite from "../../static/logomark-final-dark-theme.svg";
import logo from "../../static/logomark-final-light-theme.svg";
import lettersWhite from "../../static/logotype-final-dark-theme.svg";
import letters from "../../static/logotype-final-light-theme.svg";
import { Box, Typography, Grid } from "@material-ui/core";

const Logo = (props) => {
  const theme = useTheme();
  const renderProps = { ...props };
  delete renderProps.letters;

  return (
    <Box {...renderProps}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <img
          width="100%"
          alt="Logo"
          src={theme.palette.type === "dark" ? logoWhite : logo}
        />
        {props.letters && (
          <img
            width="100%"
            alt="Writify"
            src={theme.palette.type === "dark" ? lettersWhite : letters}
          />
        )}
        {props.slogan && (
          <Typography component={props.slogan}>Learning by writing</Typography>
        )}
      </Grid>
    </Box>
  );
};

export default Logo;
