import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Box, Container, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import BarChartIcon from "@material-ui/icons/BarChart";
import CogLinearProgress from "../CustomUI/CogLinearProgress";
import firebase from "../../tools/firebase";
import { levels } from "../../config/levels";
import {
  ENGAGEMENT_DARK,
  ENGAGEMENT_LIGHT,
  YELLOW,
} from "../../constants/colors";
const db = firebase.firestore();

const useStyles = makeStyles((theme) => {
  const paletteType = theme.palette.type;

  return {
    title: {
      textAlign: "center",
      width: "100%",
    },
    stickToBottom: {
      width: "100%",
      position: "fixed",
      bottom: 0,
    },
    levelBox: {
      backgroundColor:
        paletteType === "dark" ? ENGAGEMENT_DARK : ENGAGEMENT_LIGHT,
      borderRadius: "6px",
    },
  };
});

function Engagement(props) {
  const classes = useStyles();
  const { user } = props.auth;
  const [experiencePoints, setExperiencePoints] = useState(0);
  const [level, setLevel] = useState(1);
  const [maxPoints, setMaxPoints] = useState(500);
  const theme = useTheme();

  useEffect(() => {
    const unsubscribe = db
      .collection("platform")
      .doc("telegram")
      .collection("users")
      .doc(`${user.id}`)
      .collection("stats")
      .doc(`experience`)
      .onSnapshot(function (doc) {
        const exp = doc.data() ? doc.data().points || 0 : 0;
        setExperiencePoints(exp);
      });
    return unsubscribe;
  }, [user.id]);

  useEffect(() => {
    for (const levelIndex in levels) {
      if (
        levels[levelIndex].from <= experiencePoints &&
        experiencePoints <= levels[levelIndex].to
      ) {
        setLevel(levelIndex);
        setMaxPoints(levels[levelIndex].to + 1);
      }
    }
  }, [experiencePoints]);

  return (
    <>
      <Box
        maxWidth="xs"
        mt="20px"
        mx="auto"
        py="10px"
        px="20px"
        width="100%"
        classes={{ root: classes.levelBox }}
      >
        <Typography variant="h6">ENGAGEMENT</Typography>
        <Typography
          width="100%"
          style={{
            color: YELLOW,
            fontSize: "5em",
            textAlign: "center",
          }}
        >
          Lvl. {level}
        </Typography>
        <Typography
          width="100%"
          style={{
            color: YELLOW,
            fontSize: "2.7em",
            textAlign: "center",
          }}
        >
          {experiencePoints}
          <sup
            style={{
              fontSize: "0.4em",
            }}
          >
            XP
          </sup>
          /{maxPoints}
          <sup
            style={{
              fontSize: "0.4em",
            }}
          >
            XP
          </sup>
        </Typography>
        <CogLinearProgress
          variant="determinate"
          value={(experiencePoints / maxPoints) * 100}
        />
      </Box>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Engagement);
