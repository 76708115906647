import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import authReducer from "./auth";
import apiStatusReducer from "./apiStatus";
import exerciseReducer from "./exercise";
import notificationReducer from "./notification";

const createRootReducer = (history) =>
  combineReducers({
    auth: authReducer,
    apiStatusReducer,
    router: connectRouter(history),
    exercise: exerciseReducer,
    notifications: notificationReducer,
  });

export default createRootReducer;
