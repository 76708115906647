import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import {
  MenuItem,
  Divider,
  ListItemSecondaryAction,
  Checkbox,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import firebase from "../../tools/firebase";

const db = firebase.firestore();

function UsersDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose(selectedUsers);
  };

  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    const unsubscribe = db
      .collectionGroup("users")
      .onSnapshot(function (querySnapshot) {
        let usersArray = [];
        querySnapshot.forEach(function (doc) {
          const docData = doc.data();
          usersArray.push({
            id: doc.id,
            name: `${docData.first_name} ${docData.last_name || ""}`,
          });
        });
        setUsers(usersArray);
      });
    return unsubscribe;
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Choose Users</DialogTitle>
      <DialogContent>
        <List>
          {users.map((userObject) => {
            return (
              <React.Fragment key={userObject.id}>
                <MenuItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar
                      alt={userObject.name}
                      src="/static/images/avatar/1.jpg"
                    />
                  </ListItemAvatar>
                  <ListItemText primary={userObject.name} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      checked={selectedUsers[userObject.id] || false}
                      inputProps={{
                        "aria-labelledby": `label${userObject.id}`,
                      }}
                      onChange={(event) =>
                        setSelectedUsers({
                          ...selectedUsers,
                          [userObject.id]: event.target.checked,
                        })
                      }
                    />
                  </ListItemSecondaryAction>
                </MenuItem>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UsersDialog;
