import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import Button from "@material-ui/core/Button";
import { routes } from "../../routes";
import { connect } from "react-redux";

import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  CONTRAST_COLOR,
} from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "10px",
    display: "flex",
    width: "100%",
    maxWidth: "345px",
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const NEW = 0;
const COMPLETED = 1;
const PENDING = 2;

function TaskCard(props) {
  const classes = useStyles();
  const { task } = props;
  const type =
    task.attemptedExercises === 0
      ? NEW
      : task.solvedExercises === task.exerciseNumber
      ? COMPLETED
      : PENDING;

  const handleContinueExercises = () => {
    props.history.push(`${routes.EXERCISES.path}/${task.taskId}`);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            className={classes.avatar}
            style={{
              backgroundColor: "transparent",
              color:
                type === NEW
                  ? CONTRAST_COLOR
                  : type === COMPLETED
                  ? PRIMARY_COLOR
                  : SECONDARY_COLOR,
            }}
          >
            {type === PENDING && <NewReleasesIcon />}
            {type === COMPLETED && <CheckCircleIcon />}
            {type === NEW && <FiberNewIcon />}
          </Avatar>
        }
        title={task.title}
        subheader={
          type === NEW
            ? "New Exercises"
            : `Completed ${task.solvedExercises}/${task.exerciseNumber}`
        }
        style={{
          width: "100%",
        }}
      />

      <CardActions
        disableSpacing
        style={{
          width: "80px",
          flexDirection: "column",
          justifyContent: "center",
          marginRight: "5px",
        }}
      >
        {type === NEW && (
          <Button color="primary" onClick={() => handleContinueExercises()}>
            Start
          </Button>
        )}
        {type === COMPLETED && <Button color="secondary">Restart</Button>}
        {type === PENDING && (
          <>
            <Button color="primary" onClick={() => handleContinueExercises()}>
              Continue
            </Button>
            <Button color="secondary">Restart</Button>
          </>
        )}
      </CardActions>
    </Card>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(TaskCard);
