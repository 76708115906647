import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  SIGNUP_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
} from "./actionTypes";

import myFirebase from "../../tools/firebase";

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const receiveLogin = (user) => {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
};

const loginError = () => {
  return {
    type: LOGIN_FAILURE,
  };
};

const requestSignup = () => {
  return {
    type: SIGNUP_REQUEST,
  };
};

const receiveSignup = (user) => {
  return {
    type: SIGNUP_SUCCESS,
    user,
  };
};

const signUpError = () => {
  return {
    type: SIGNUP_FAILURE,
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE,
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST,
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS,
  };
};

export const loginUser = (email, password) => (dispatch) => {
  dispatch(requestLogin());
  myFirebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((user) => {
      dispatch(receiveLogin(getTelegramUser(user.user)));
    })
    .catch((error) => {
      //Do something with the error if you want!
      dispatch(loginError());
    });
};

export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout());
  myFirebase
    .auth()
    .signOut()
    .then(() => {
      dispatch(receiveLogout());
    })
    .catch((error) => {
      //Do something with the error if you want!
      dispatch(logoutError());
    });
};

export const verifyAuth = () => (dispatch) => {
  dispatch(verifyRequest());
  myFirebase.auth().onAuthStateChanged(async (user) => {
    if (user !== null) {
      dispatch(receiveLogin(await getTelegramUser(user)));
    }
    dispatch(verifySuccess());
  });
};

export const signUpUser = (email, password, telegramId) => (dispatch) => {
  dispatch(requestSignup());
  myFirebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      const db = myFirebase.firestore();
      db.collection("platform")
        .doc("telegram")
        .collection("users")
        .doc(`${telegramId}`)
        .set(
          {
            firebaseId: userCredential.user.uid,
          },
          { merge: true }
        )
        .then(async () =>
          dispatch(receiveSignup(await getTelegramUser(userCredential.user)))
        );
    })
    .catch((error) => {
      //Do something with the error if you want!
      dispatch(signUpError());
    });
};

const getTelegramUser = async (user) => {
  const db = myFirebase.firestore();
  const querySnapshot = await db
    .collection("platform")
    .doc("telegram")
    .collection("users")
    .where("firebaseId", "==", user.uid)
    .get();
  let userObject = {};
  if (querySnapshot) {
    querySnapshot.forEach(function (doc) {
      userObject = doc.data();
    });
  }
  return userObject;
};
