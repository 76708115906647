import React, { useState, useEffect } from "react";
import { Box, Container, Avatar, Paper, Grid } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import firebase from "../../tools/firebase";
import cogIcon from "../../static/cog.jpeg";
import { connect } from "react-redux";
import SpeechBubble from "../CustomUI/SpeechBubble";
import TaskCard from "../TaskCard";
import CogChat from "../CogChat";

const db = firebase.firestore();

const useStyles = makeStyles((theme) => {
  const paletteType = theme.palette.type;

  return {
    mainScreen: {
      padding: "20px",
    },
    avatar: {
      borderStyle: paletteType === "dark" ? "none" : "solid",
      borderColor: "black",
      borderWidth: "1px",
    },
  };
});

function TaskList(props) {
  const classes = useStyles();
  const { user } = props.auth;

  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTasks = async () => {
      const collectionsArray = [];
      const result = await db
        .collection("platform")
        .doc("telegram")
        .collection("users")
        .doc(`${user.id}`)
        .collection("tasks")
        .get();

      if (result.empty) {
        setCollections(collectionsArray);
        setLoading(false);
        return;
      }

      let tasks = [];

      result.forEach((taskDoc) => {
        tasks.push({ taskId: taskDoc.id, ...taskDoc.data() });
      });

      for (const task of tasks) {
        const exerciseResult = await db
          .collection("platform")
          .doc("telegram")
          .collection("users")
          .doc(`${user.id}`)
          .collection("tasks")
          .doc(`${task.taskId}`)
          .collection("exercises")
          .get();

        if (!exerciseResult.empty) {
          let exerciseNumber = 0;
          let solvedExercises = 0;
          let attemptedExercises = 0;
          let exercises = [];

          exerciseResult.forEach((doc) => {
            exercises.push(doc.data());
          });

          for (const exercise of exercises) {
            if (exercise.solved) {
              solvedExercises++;
            }
            if (exercise.answers && exercise.answers.length > 0) {
              attemptedExercises++;
            }
            exerciseNumber++;
          }

          collectionsArray.push({
            ...task,
            exerciseNumber,
            solvedExercises,
            attemptedExercises,
          });
        }
      }

      setCollections(collectionsArray);
      setLoading(false);
    };

    if (user && user.id) {
      fetchTasks();
    }
  }, [user]);
  const botText = loading
    ? "We are fetching your exercises"
    : collections.length > 0
    ? "Choose your tasks from the list"
    : "Sorry, but we have no exercises for you";
  return (
    <>
      <Container maxWidth="sm" className={classes.mainScreen}>
        <CogChat>{botText}</CogChat>

        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          style={{ marginTop: "20px" }}
        >
          {collections &&
            collections.length > 0 &&
            collections.map((task) => (
              <TaskCard key={task.taskId} task={task} {...props} />
            ))}
        </Grid>
      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(TaskList);
