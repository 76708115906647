import React, { useState, useEffect } from "react";
import { Box, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

function UnnecessaryWord(props) {
  const classes = useStyles();
  const textArray = props.step.original.split(" ");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleWordClick = (index) => {
    setSelectedIndex(index);
    let answer = textArray;
    answer.splice(index, 1);
    props.setAnswer(answer.join(" "));
  };
  console.log(selectedIndex);
  return (
    <>
      <Box
        style={{
          lineHeight: "2em",
          fontSize: "1rem",
          textAlign: "center",
        }}
      >
        {props.correct === true && (
          <Box
            component="span"
            style={{
              color: "green",
              fontSize: "2em",
            }}
          >
            {props.step.solution}
          </Box>
        )}
        {props.correct !== true && (
          <>
            {textArray &&
              textArray.map((word, index) => {
                const color = index === selectedIndex ? "red" : "inherit";
                return (
                  <Box
                    component="span"
                    style={{
                      color: color,
                      fontSize: "2em",
                    }}
                    key={index}
                    onClick={() => handleWordClick(index)}
                  >
                    {`${word} `}
                  </Box>
                );
              })}
          </>
        )}
      </Box>
      <Box mx="auto" mt="20px" style={{ textAlign: "center" }}>
        {!props.correct && (
          <Button
            variant="contained"
            color="primary"
            onClick={props.checkCorrect}
          >
            Submit
          </Button>
        )}

        {props.correct === true && (
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "green" }}
            onClick={props.nextStep}
          >
            Next
          </Button>
        )}
      </Box>
    </>
  );
}

export default UnnecessaryWord;
