import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Avatar,
  Paper,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import firebase from "../../tools/firebase";
import FillTheGap from "../FillTheGap";
import Footer from "../Footer";
import cogIcon from "../../static/cog-dark-on-orange-bg.png";
import { connect } from "react-redux";
import SpeechBubble from "../CustomUI/SpeechBubble";
import {
  FILL_IN_THE_GAP,
  MISSING_WORD,
  UNNECESSARY_WORD,
} from "../../constants/exerciseTypes";
import MissingWord from "../MissingWord";
import UnnecessaryWord from "../UnnecessaryWord";
import CogChat from "../CogChat";

const db = firebase.firestore();

const useStyles = makeStyles((theme) => {
  const paletteType = theme.palette.type;

  return {
    stickToBottom: {
      width: "100%",
      position: "fixed",
      bottom: 0,
    },
    mainScreen: {
      padding: "20px",
    },
    avatar: {
      borderStyle: paletteType === "dark" ? "none" : "solid",
      borderColor: "black",
      borderWidth: "1px",
    },
  };
});

function Exercises(props) {
  const classes = useStyles();
  const [step, setStep] = useState(0);

  useEffect(() => {
    const fetchExercises = async () => {
      let taskId = props.match.params.taskId;
      if (!taskId) {
        const result = await db
          .collection("platform")
          .doc("telegram")
          .collection("users")
          .doc(`${props.auth.user.id}`)
          .collection("tasks")
          .where("default", "==", true)
          .get();

        if (result.empty) {
          setSteps([]);
          return;
        }

        taskId = false;
        result.forEach((doc) => {
          taskId = doc.id;
        });
      }

      if (taskId) {
        const exerciseResult = await db
          .collection("platform")
          .doc("telegram")
          .collection("users")
          .doc(`${props.auth.user.id}`)
          .collection("tasks")
          .doc(`${taskId}`)
          .collection("exercises")
          .get();

        if (exerciseResult.empty) {
          console.log("empty response");
          setSteps([]);
          return;
        }

        let exercisesArray = [];
        exerciseResult.forEach((doc) => {
          const docData = doc.data();
          if (!docData.solved) {
            exercisesArray.push({
              ...docData,
              taskId: taskId,
              exerciseId: doc.id,
              instructions: docData.title,
              type: docData.type,
              firstText: docData.firstText,
              secondText: docData.secondText,
              fill: docData.hint,
              answer: docData.solution,
              answers: docData.answers || [],
            });
          }
        });
        setSteps(exercisesArray);
        return;
      }
      setSteps([]);
    };
    fetchExercises();
  }, []);

  const nextStep = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const [steps, setSteps] = useState(null);

  const [correct, setCorrect] = useState(0);
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState(false);

  const checkCorrect = () => {
    let correctAnswer = false;
    if (
      steps[step].type === MISSING_WORD ||
      steps[step].type === FILL_IN_THE_GAP
    ) {
      correctAnswer = answer === steps[step].answer;
      setCorrect(correctAnswer);
      setError(!correctAnswer);
    }

    if (steps[step].type === UNNECESSARY_WORD) {
      correctAnswer = answer === steps[step].solution;
      setCorrect(correctAnswer);
      setError(!correctAnswer);
    }

    let answersArray = steps[step].answers;
    answersArray.push(answer);
    db.collection("platform")
      .doc("telegram")
      .collection("users")
      .doc(`${props.auth.user.id}`)
      .collection("tasks")
      .doc(`${steps[step].taskId}`)
      .collection("exercises")
      .doc(`${steps[step].exerciseId}`)
      .set(
        {
          solved: correctAnswer,
          answers: answersArray,
        },
        { merge: true }
      );
  };

  const manageAnswerChange = (event) => {
    setAnswer(event.target.value);
  };

  useEffect(() => {
    setCorrect(false);
    setAnswer("");
    setError(false);
  }, [step]);

  const exerciseProps = {
    manageAnswerChange,
    checkCorrect,
    nextStep,
    correct,
    answer,
    error,
    setAnswer,
  };

  const tooltipText = correct
    ? `That's right!`
    : error
    ? `No. Try again.`
    : steps
    ? steps[step]?.instructions || ""
    : "";

  return (
    <>
      {steps === null && <Paper>Loading...</Paper>}
      {steps && steps.length === 0 && (
        <Paper>Sorry, but there are no exercises for you</Paper>
      )}
      {steps && steps.length > 0 && (
        <>
          <Container maxWidth="sm" className={classes.mainScreen}>
            <CogChat>{tooltipText}</CogChat>

            <Box mt="100px" m="auto">
              <Card>
                <CardContent>
                  {steps[step].type === MISSING_WORD && (
                    <MissingWord step={steps[step]} {...exerciseProps} />
                  )}
                  {steps[step].type === FILL_IN_THE_GAP && (
                    <FillTheGap step={steps[step]} {...exerciseProps} />
                  )}
                  {steps[step].type === UNNECESSARY_WORD && (
                    <UnnecessaryWord step={steps[step]} {...exerciseProps} />
                  )}
                </CardContent>
              </Card>
            </Box>
          </Container>
          <Footer
            step={step}
            stepNum={steps.length}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        </>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Exercises);
