import React, { useState, useEffect } from "react";
import { getErrant } from "../../tools/nlp";
import firebase from "../../tools/firebase";
import {
  Typography,
  Grid,
  Hidden,
  List,
  ListItem,
  Divider,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Avatar,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
  Box,
  Paper,
  Checkbox,
  ListItemSecondaryAction,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";
import cogIcon from "../../static/cog.jpeg";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import LockIcon from "@material-ui/icons/Lock";
import { useSnackbar } from "notistack";
import { connect, useDispatch } from "react-redux";
import {
  deleteTask,
  deleteExercise,
  updateTaskName,
  makeTaskDefault,
  updateExercise,
  newExercise,
  copyExercise,
} from "../../stores/actions/exercise";
import EditExercise from "../EditExercise";
import UsersDialog from "../UsersDialog";

const db = firebase.firestore();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

const RenderUsers = (props) => {
  const { users, setUser, setTask, setExercise, user } = props;
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      {users.length === 0 && <CircularProgress />}
      {users.length > 0 && (
        <List className={classes.root}>
          {users.map((userObject) => {
            return (
              <React.Fragment key={userObject.id}>
                <MenuItem
                  alignItems="flex-start"
                  onClick={() => {
                    setUser(userObject.id);
                    setTask(null);
                    setExercise(null);
                  }}
                  button
                  selected={userObject.id === user ? true : false}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={userObject.name}
                      src="/static/images/avatar/1.jpg"
                    />
                  </ListItemAvatar>
                  <ListItemText primary={userObject.name} />
                </MenuItem>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            );
          })}
        </List>
      )}
    </Grid>
  );
};

const RenderTasks = (props) => {
  const classes = useStyles();
  const {
    taskArray,
    setUser,
    handleClick,
    anchorEl,
    open,
    handleClose,
    handleDeleteTask,
    setTaskObject,
    setTask,
    task,
    selectedTasks,
    setSelectedTasks,
    createNewTask,
  } = props;

  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Paper styles={{ maxHeight: "100%", overflow: "auto" }}>
          {taskArray.length === 0 && <CircularProgress />}
          <List className={classes.root} style={{ width: "100%" }}>
            <MenuItem button onClick={() => setUser(null)}>
              <ListItemIcon>
                <ArrowBackIosIcon />
              </ListItemIcon>
              <ListItemText primary="Back" />
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem disabled onClick={handleClose}>
                    <ListItemIcon>
                      <FileCopyIcon fontSize="small" />
                    </ListItemIcon>
                    Copy
                  </MenuItem>
                  <MenuItem onClick={handleDeleteTask}>
                    <ListItemIcon>
                      <DeleteForeverIcon fontSize="small" />
                    </ListItemIcon>
                    Delete
                  </MenuItem>
                </Menu>
              </ListItemSecondaryAction>
            </MenuItem>
            <Divider component="li" />
            {taskArray.length > 0 && (
              <>
                {taskArray.map((taskObject) => {
                  return (
                    <MenuItem
                      button
                      key={`${taskObject.id}`}
                      onClick={() => {
                        setTaskObject(taskObject);
                        setTask(taskObject.id);
                      }}
                      selected={taskObject.id === task}
                    >
                      {taskObject.default === true && (
                        <ListItemIcon>
                          <LockIcon aria-label="Default" />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        id={`label${taskObject.id}`}
                        primary={`${taskObject.title}`}
                        inset={taskObject.default !== true}
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          checked={selectedTasks[taskObject.id] || false}
                          inputProps={{
                            "aria-labelledby": `label${taskObject.id}`,
                          }}
                          onChange={(event) =>
                            setSelectedTasks({
                              ...selectedTasks,
                              [taskObject.id]: event.target.checked,
                            })
                          }
                        />
                      </ListItemSecondaryAction>
                    </MenuItem>
                  );
                })}
              </>
            )}
            <Divider variant="inset" component="li" />
            <MenuItem key="newTask" onClick={() => createNewTask()}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary={`New Task`} />
            </MenuItem>
          </List>
        </Paper>
      </Grid>
    </>
  );
};

const RenderExercises = (props) => {
  const classes = useStyles();
  const {
    setTask,
    taskObject,
    handleTaskNameChange,
    handleMakeDefaultTask,
    saveTask,
    handleClickEx,
    anchorEx,
    openEx,
    handleCloseEx,
    handleCopyExercise,
    handleDeleteExercise,
    exerciseArray,
    setExercise,
    exercise,
    selectedExercises,
    setSelectedExercises,
  } = props;
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <List className={classes.root} style={{ width: "100%" }}>
        <MenuItem button onClick={() => setTask(null)}>
          <ListItemIcon>
            <ArrowBackIosIcon />
          </ListItemIcon>
          <ListItemText primary="Back" />
        </MenuItem>

        <Divider component="li" />

        <ListItem>
          <ListItemText primary={`Task Name`} />
          <TextField value={taskObject.title} onChange={handleTaskNameChange} />
        </ListItem>
        <ListItem color="primary">
          <Button
            color="primary"
            disabled={taskObject.default}
            onClick={handleMakeDefaultTask}
          >
            Make Default
          </Button>
          <Button color="primary" onClick={saveTask}>
            Save
          </Button>
        </ListItem>

        <Divider component="li" />

        <ListItem>
          <ListItemText primary="Exercises" />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClickEx}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEx}
              keepMounted
              open={openEx}
              onClose={handleCloseEx}
            >
              <MenuItem onClick={handleCopyExercise}>
                <ListItemIcon>
                  <FileCopyIcon fontSize="small" />
                </ListItemIcon>
                Copy
              </MenuItem>
              <MenuItem onClick={handleDeleteExercise}>
                <ListItemIcon>
                  <DeleteForeverIcon fontSize="small" />
                </ListItemIcon>
                Delete
              </MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        </ListItem>
        {exerciseArray.length > 0 && (
          <>
            <Divider variant="inset" component="li" />
            {exerciseArray.map((exerciseObject) => {
              const inset =
                !exerciseObject.answers || exerciseObject.answers.length === 0;

              return (
                <MenuItem
                  button
                  onClick={() => setExercise(exerciseObject.id)}
                  key={`${exerciseObject.id}`}
                  selected={exercise === exerciseObject.id}
                >
                  {exerciseObject.solved === true && (
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                  )}

                  {exerciseObject.answers &&
                    exerciseObject.answers.length > 0 &&
                    exerciseObject.solved !== true && (
                      <ListItemIcon>
                        <CloseIcon />
                      </ListItemIcon>
                    )}
                  <ListItemText
                    id={`label${exerciseObject.id}`}
                    primary={`${exerciseObject.id}`}
                    secondary={`${exerciseObject.text}`}
                    inset={inset}
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      checked={selectedExercises[exerciseObject.id] || false}
                      inputProps={{
                        "aria-labelledby": `label${exerciseObject.id}`,
                      }}
                      onChange={(event) =>
                        setSelectedExercises({
                          ...selectedExercises,
                          [exerciseObject.id]: event.target.checked,
                        })
                      }
                    />
                  </ListItemSecondaryAction>
                </MenuItem>
              );
            })}
          </>
        )}
        <Divider variant="inset" component="li" />
        <MenuItem
          key="newExercise"
          onClick={() => setExercise("new")}
          selected={exercise === "new"}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={`New Exercise`} />
        </MenuItem>
      </List>
    </Grid>
  );
};

function UserExercisesAdmin(props) {
  const [user, setUser] = useState(null);
  const [task, setTask] = useState(null);
  const [exercise, setExercise] = useState(null);
  const [users, setUsers] = useState([]);
  const [taskArray, setTaskArray] = useState([]);
  const [exerciseArray, setExerciseArray] = useState([]);
  const [taskObject, setTaskObject] = useState({});
  const [exerciseObject, setExerciseObject] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [anchorEx, setAnchorEx] = useState(null);
  const openEx = Boolean(anchorEx);
  const [selectedTasks, setSelectedTasks] = useState({});
  const [selectedExercises, setSelectedExercises] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickEx = (event) => {
    setAnchorEx(event.currentTarget);
  };

  const handleCloseEx = () => {
    setAnchorEx(null);
  };

  const handleDeleteTask = () => {
    Object.keys(selectedTasks).forEach((taskId) => {
      if (selectedTasks[taskId] === true) {
        dispatch(deleteTask({ userId: user, taskId }));
      }
    });
    setSelectedTasks({});
    setTask("");
    handleClose();
  };

  const handleDeleteExercise = () => {
    Object.keys(selectedExercises).forEach((exerciseId) => {
      if (selectedExercises[exerciseId] === true) {
        dispatch(
          deleteExercise({ userId: user, taskId: task, exerciseId: exerciseId })
        );
      }
    });
    setSelectedExercises({});
    setExercise("");
    handleCloseEx();
  };

  const classes = useStyles();

  // this will run only once
  useEffect(() => {
    const unsubscribe = db
      .collectionGroup("users")
      .onSnapshot(function (querySnapshot) {
        let usersArray = [];
        querySnapshot.forEach(function (doc) {
          const docData = doc.data();
          usersArray.push({
            id: doc.id,
            name: `${docData.first_name} ${docData.last_name || ""}`,
          });
        });
        setUsers(usersArray);
      });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (user) {
      const unsubscribe = db
        .collection("platform")
        .doc("telegram")
        .collection("users")
        .doc(`${user}`)
        .collection("tasks")
        .onSnapshot(function (querySnapshot) {
          let tasksArray = [];
          querySnapshot.forEach(function (doc) {
            const docData = doc.data();
            tasksArray.push({
              id: doc.id,
              title: docData.title,
              default: docData.default,
            });
          });
          setTaskArray(tasksArray);
        });
      return unsubscribe;
    }

    if (!user) {
      setTaskArray([]);
      return;
    }
  }, [user]);

  useEffect(() => {
    if (task) {
      const unsubscribe = db
        .collection("platform")
        .doc("telegram")
        .collection("users")
        .doc(`${user}`)
        .collection("tasks")
        .doc(`${task}`)
        .collection("exercises")
        .onSnapshot(function (querySnapshot) {
          let exercisesArray = [];
          querySnapshot.forEach(function (doc) {
            const docData = doc.data();
            exercisesArray.push({
              id: doc.id,
              text: docData.firstText || docData.original,
              solved: docData.solved,
              answers: docData.answers,
            });
          });
          setExerciseArray(exercisesArray);
        });
      return unsubscribe;
    }

    if (!user) {
      setTaskArray([]);
      return;
    }
  }, [user, task]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await db
        .collection("platform")
        .doc("telegram")
        .collection("users")
        .doc(`${user}`)
        .collection("tasks")
        .doc(`${task}`)
        .collection("exercises")
        .doc(`${exercise}`)
        .get();
      if (response.empty) {
        console.log("empty response");
        return;
      }

      const docData = response.data();
      if (docData) {
        setExerciseObject(docData);
        return;
      }
    };
    if (!user || !task) {
      setExerciseObject({});
      return;
    }
    if (exercise === "new") {
      setExerciseObject({
        type: "FILL_IN_THE_GAP",
        firstText: "",
        secondText: "",
        hint: "",
        solution: "",
        title: "",
      });
      return;
    }
    fetchData();
  }, [user, task, exercise]);

  const createNewTask = async () => {
    const response = await db
      .collection("platform")
      .doc("telegram")
      .collection("users")
      .doc(`${user}`)
      .collection("tasks")
      .add({
        default: false,
        title: "New Task",
      });

    setTask(response.id);
    setExercise(null);
  };

  const saveTask = () => {
    dispatch(
      updateTaskName({
        userId: user,
        taskId: task,
        taskName: taskObject.title,
      })
    );
  };

  const handleTaskNameChange = (event) => {
    setTaskObject({
      ...taskObject,
      title: event.target.value,
    });
  };

  const handleMakeDefaultTask = () => {
    setTaskObject({
      ...taskObject,
      default: true,
    });
    dispatch(makeTaskDefault(user, task));
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (users) => {
    setOpenDialog(false);
    if (users) {
      for (const userId in users) {
        if (users[userId]) {
          for (const exerciseId in selectedExercises) {
            if (selectedExercises[exerciseId]) {
              dispatch(
                copyExercise({
                  exerciseObject: exerciseArray.find(
                    (obj) => obj.id === exerciseId
                  ),
                  exerciseId,
                  userId,
                })
              );
            }
          }
        }
      }
    }
  };

  const handleCopyExercise = () => {
    handleClickOpenDialog();
    handleCloseEx();
  };

  const handleExerciseChange = (event) => {
    setExerciseObject({
      ...exerciseObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleUpdateExercise = () => {
    if (exercise === "new") {
      dispatch(newExercise({ userId: user, taskId: task, exerciseObject }));
    }
    if (exercise !== "new") {
      dispatch(
        updateExercise({
          userId: user,
          taskId: task,
          exerciseId: exercise,
          exerciseObject,
        })
      );
    }
    setExercise(null);
  };

  return (
    <>
      <UsersDialog open={openDialog} onClose={handleCloseDialog} />
      {props.auth.user.admin !== true && (
        <Paper>You don't have permission to see this page</Paper>
      )}
      {props.auth.user.admin === true && (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          {!user && (
            <RenderUsers
              users={users}
              setUser={setUser}
              setTask={setTask}
              setExercise={setExercise}
              user={user}
            />
          )}
          {user && !task && (
            <>
              <Hidden xsDown>
                <RenderUsers
                  users={users}
                  setUser={setUser}
                  setTask={setTask}
                  setExercise={setExercise}
                  user={user}
                />
              </Hidden>
              <RenderTasks
                taskArray={taskArray}
                setUser={setUser}
                handleClick={handleClick}
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                handleDeleteTask={handleDeleteTask}
                setTaskObject={setTaskObject}
                setTask={setTask}
                task={task}
                selectedTasks={selectedTasks}
                setSelectedTasks={setSelectedTasks}
                createNewTask={createNewTask}
              />
            </>
          )}
          {user && task && !exercise && (
            <>
              <Hidden smDown>
                <RenderUsers
                  users={users}
                  setUser={setUser}
                  setTask={setTask}
                  setExercise={setExercise}
                  user={user}
                />
              </Hidden>
              <Hidden xsDown>
                <RenderTasks
                  taskArray={taskArray}
                  setUser={setUser}
                  handleClick={handleClick}
                  anchorEl={anchorEl}
                  open={open}
                  handleClose={handleClose}
                  handleDeleteTask={handleDeleteTask}
                  setTaskObject={setTaskObject}
                  setTask={setTask}
                  task={task}
                  selectedTasks={selectedTasks}
                  setSelectedTasks={setSelectedTasks}
                  createNewTask={createNewTask}
                />
              </Hidden>
              <RenderExercises
                setTask={setTask}
                taskObject={taskObject}
                handleTaskNameChange={handleTaskNameChange}
                handleMakeDefaultTask={handleMakeDefaultTask}
                saveTask={saveTask}
                handleClickEx={handleClickEx}
                anchorEx={anchorEx}
                openEx={openEx}
                handleCloseEx={handleCloseEx}
                handleCopyExercise={handleCopyExercise}
                handleDeleteExercise={handleDeleteExercise}
                exerciseArray={exerciseArray}
                setExercise={setExercise}
                exercise={exercise}
                selectedExercises={selectedExercises}
                setSelectedExercises={setSelectedExercises}
              />
            </>
          )}
          {user && task && exercise && (
            <>
              <Hidden mdDown>
                <RenderUsers
                  users={users}
                  setUser={setUser}
                  setTask={setTask}
                  setExercise={setExercise}
                  user={user}
                />
              </Hidden>
              <Hidden smDown>
                <RenderTasks
                  taskArray={taskArray}
                  setUser={setUser}
                  handleClick={handleClick}
                  anchorEl={anchorEl}
                  open={open}
                  handleClose={handleClose}
                  handleDeleteTask={handleDeleteTask}
                  setTaskObject={setTaskObject}
                  setTask={setTask}
                  task={task}
                  selectedTasks={selectedTasks}
                  setSelectedTasks={setSelectedTasks}
                  createNewTask={createNewTask}
                />
              </Hidden>
              <Hidden xsDown>
                <RenderExercises
                  setTask={setTask}
                  taskObject={taskObject}
                  handleTaskNameChange={handleTaskNameChange}
                  handleMakeDefaultTask={handleMakeDefaultTask}
                  saveTask={saveTask}
                  handleClickEx={handleClickEx}
                  anchorEx={anchorEx}
                  openEx={openEx}
                  handleCloseEx={handleCloseEx}
                  handleCopyExercise={handleCopyExercise}
                  handleDeleteExercise={handleDeleteExercise}
                  exerciseArray={exerciseArray}
                  setExercise={setExercise}
                  exercise={exercise}
                  selectedExercises={selectedExercises}
                  setSelectedExercises={setSelectedExercises}
                />
              </Hidden>
              <EditExercise
                exerciseObject={exerciseObject}
                handleExerciseChange={handleExerciseChange}
                handleUpdateExercise={handleUpdateExercise}
                classes={classes}
                setExercise={setExercise}
                exercise={exercise}
                key={`exercise`}
              />
            </>
          )}
        </Grid>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(UserExercisesAdmin);
