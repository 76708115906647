import React from "react";
import { Box, Container, Button } from "@material-ui/core";
import { routes } from "../../routes";
import { makeStyles } from "@material-ui/core/styles";
import Image from "material-ui-image";
import BotImage from "../../static/Ellipse2.png";
import { Route, Redirect } from "react-router-dom";

const useStyles = makeStyles({
  title: {
    textAlign: "center",
    width: "100%",
  },
  stickToBottom: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  mainScreen: {
    paddingTop: "20px",
  },
});

function Landing(props) {
  const classes = useStyles();

  return (
    <>
      <Redirect
        to={{
          pathname: routes.HOME.path,
          state: { from: props.location },
        }}
      />
      <Container maxWidth="sm" className={classes.mainScreen}>
        <Box mt="20px" m="auto">
          <Box m="auto">
            <Image
              src={BotImage}
              style={{ backgroundColor: "transparent" }}
            ></Image>
          </Box>
          <Box
            mt="20px"
            px="30px"
            py="10px"
            style={{ backgroundColor: "rgba(210, 38, 238, 0.28)" }}
          >
            COG has sent you some new exercises based on your recent work!
          </Box>
          <Box mt="20px" style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.history.push(`${routes.EXERCISES.path}`)}
            >
              LET'S GO
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Landing;
