import {
  FILL_IN_THE_GAP,
  MISSING_WORD,
  UNNECESSARY_WORD,
} from "../constants/exerciseTypes";

export const EXERCISE_TITLES = {
  [FILL_IN_THE_GAP]: "Correct the word in red",
  [MISSING_WORD]: "Type the missing word",
  [UNNECESSARY_WORD]: "Click on the unnecessary word",
};
