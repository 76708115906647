import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import {
  MenuItem,
  Divider,
  ListItemSecondaryAction,
  Checkbox,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

function TaskDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose(selectedTasks);
  };

  const { tasks } = props;
  const [selectedTasks, setSelectedTasks] = useState([]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Choose Tasks</DialogTitle>
      <DialogContent>
        <List>
          {tasks.map((taskObject) => {
            return (
              <React.Fragment key={taskObject.id}>
                <MenuItem button alignItems="flex-start">
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      checked={selectedTasks[taskObject.id] || false}
                      inputProps={{
                        "aria-labelledby": `label${taskObject.id}`,
                      }}
                      onChange={(event) =>
                        setSelectedTasks({
                          ...selectedTasks,
                          [taskObject.id]: event.target.checked,
                        })
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={taskObject.title} />
                  <ListItemSecondaryAction></ListItemSecondaryAction>
                </MenuItem>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TaskDialog;
