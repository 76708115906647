import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Box, Container, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import BarChartIcon from "@material-ui/icons/BarChart";
import CogLinearProgress from "../CustomUI/CogLinearProgress";
import firebase from "../../tools/firebase";
import { levels } from "../../config/levels";
import {
  ENGAGEMENT_DARK,
  ENGAGEMENT_LIGHT,
  YELLOW,
  PROFILE,
  EFFORT_BACKGROUND,
} from "../../constants/colors";
import Engagement from "../Engagement";
import StatsWordChart from "../StatsWordChart";
import StatsExercisesChart from "../StatsExercisesChart";
const db = firebase.firestore();

const useStyles = makeStyles((theme) => {
  const paletteType = theme.palette.type;

  return {
    title: {
      textAlign: "center",
      width: "100%",
    },
    stickToBottom: {
      width: "100%",
      position: "fixed",
      bottom: 0,
    },
    mainScreen: {
      paddingTop: "20px",
    },
    signOutButton: {
      backgroundColor: paletteType === "dark" ? "inherit" : YELLOW,
      color: paletteType === "dark" ? YELLOW : "inherit",
      borderColor: paletteType === "dark" ? YELLOW : "inherit",
    },
    effortBox: {
      backgroundColor:
        paletteType === "dark" ? EFFORT_BACKGROUND : EFFORT_BACKGROUND,
      borderRadius: "6px",
    },
  };
});

function Dashboard(props) {
  const classes = useStyles();
  const { auth } = props;
  return (
    <>
      <Container maxWidth="sm" className={classes.mainScreen}>
        <Box>
          <Typography>
            <BarChartIcon
              style={{
                verticalAlign: "bottom",
                color: YELLOW,
              }}
            />
            MY DASHBOARD
          </Typography>
        </Box>
        <Engagement />

        <Box
          maxWidth="xs"
          mt="20px"
          mx="auto"
          py="10px"
          px="20px"
          width="100%"
          minWidth="250px"
          classes={{ root: classes.effortBox }}
        >
          <Typography variant="h6">EFFORT</Typography>
          {auth && auth.user && (
            <>
              <Box>
                <Typography variant="subtitle1">Words per day</Typography>
                <StatsWordChart selectedUsers={[auth.user.id]} />
              </Box>
              <Box mt="15px">
                <Typography variant="subtitle1">Exercises</Typography>
                <StatsExercisesChart selectedUsers={[auth.user.id]} />
              </Box>
            </>
          )}
        </Box>
      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Dashboard);
