export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const EXERCISE_CREATE_REQUEST = "EXERCISE_CREATE_REQUEST";
export const EXERCISE_CREATE_SUCCESS = "EXERCISE_CREATE_SUCCESS";
export const EXERCISE_CREATE_FAILURE = "EXERCISE_CREATE_FAILURE";

export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const TASK_DELETE_REQUEST = "TASK_DELETE_REQUEST";
export const TASK_DELETE_SUCCESS = "TASK_DELETE_SUCCESS";
export const TASK_DELETE_FAILURE = "TASK_DELETE_FAILURE";

export const EXERCISE_DELETE_REQUEST = "EXERCISE_DELETE_REQUEST";
export const EXERCISE_DELETE_SUCCESS = "EXERCISE_DELETE_SUCCESS";
export const EXERCISE_DELETE_FAILURE = "EXERCISE_DELETE_FAILURE";

export const UPDATE_TASK_REQUEST = "UPDATE_TASK_REQUEST";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_FAILURE = "UPDATE_TASK_FAILURE";

export const UPDATE_EXERCISE_REQUEST = "UPDATE_EXERCISE_REQUEST";
export const UPDATE_EXERCISE_SUCCESS = "UPDATE_EXERCISE_SUCCESS";
export const UPDATE_EXERCISE_FAILURE = "UPDATE_EXERCISE_FAILURE";

export const COPY_EXERCISE_REQUEST = "COPY_EXERCISE_REQUEST";
export const COPY_EXERCISE_SUCCESS = "COPY_EXERCISE_SUCCESS";
export const COPY_EXERCISE_FAILURE = "COPY_EXERCISE_FAILURE";

export const COPY_TASK_REQUEST = "COPY_TASK_REQUEST";
export const COPY_TASK_SUCCESS = "COPY_TASK_SUCCESS";
export const COPY_TASK_FAILURE = "COPY_TASK_FAILURE";
