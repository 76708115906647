import React from "react";
import { ListItem, TextField } from "@material-ui/core";

const UnnecessaryWordExercise = (props) => {
  const { exerciseObject, handleExerciseChange } = props;

  return (
    <>
      <ListItem>
        <TextField
          disabled={exerciseObject.solved}
          required
          name="title"
          label="Title"
          onChange={handleExerciseChange}
          value={exerciseObject.title}
          variant="outlined"
        />
      </ListItem>
      <ListItem>
        <TextField
          disabled={exerciseObject.solved}
          required
          name="original"
          label="Original"
          onChange={handleExerciseChange}
          value={exerciseObject.original}
          variant="outlined"
        />
      </ListItem>
      <ListItem>
        <TextField
          disabled={exerciseObject.solved}
          required
          name="solution"
          label="Solution (Only one word has to be different)"
          onChange={handleExerciseChange}
          value={exerciseObject.solution}
          variant="outlined"
        />
      </ListItem>
    </>
  );
};

export default UnnecessaryWordExercise;
