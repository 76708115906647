import React, { Component } from "react";
import {
  Box,
  Container,
  Avatar,
  Paper,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import cogIcon from "../../static/cog-dark-on-orange-bg.png";
import SpeechBubble from "../CustomUI/SpeechBubble";

const useStyles = makeStyles((theme) => {
  const paletteType = theme.palette.type;

  return {
    stickToBottom: {
      width: "100%",
      position: "fixed",
      bottom: 0,
    },
    mainScreen: {
      padding: "20px",
    },
    avatar: {
      borderStyle: paletteType === "dark" ? "none" : "solid",
      borderColor: "black",
      borderWidth: "1px",
    },
  };
});

function CogChat(props) {
  const classes = useStyles();
  return (
    <Grid container direction="row" justify="flex-start" spacing={1}>
      <Grid item>
        <Avatar alt="COG" src={cogIcon} classes={{ root: classes.avatar }} />
        <div
          style={{
            width: "40px",
            textAlign: "center",
          }}
        >
          Cog
        </div>
      </Grid>
      <SpeechBubble>{props.children}</SpeechBubble>
    </Grid>
  );
}

export default CogChat;
