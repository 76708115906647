import React, { useState, useEffect } from "react";
import firebase from "../../tools/firebase";
import {
  Grid,
  Hidden,
  List,
  ListItem,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Checkbox,
  ListItemSecondaryAction,
  TextField,
  Button,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { connect, useDispatch } from "react-redux";
import {
  deleteExercise,
  updateExercise,
  newExercise,
  copyExercise,
  updateTaskName,
} from "../../stores/actions/exercise";
import EditExercise from "../EditExercise";
import UsersDialog from "../UsersDialog";

const RenderExercises = (props) => {
  const {
    exerciseType,
    setExercise,
    setTask,
    setTaskExercise,
    taskExercises,
    exercises,
    taskObject,
    handleTaskNameChange,
    saveTask,
    handleCopyExercise,
    handleDeleteExercise,
    exercise,
    selectedExercises,
    setSelectedExercises,
    taskExercise,
    handleAddExerciseToTask,
  } = props;

  const [anchorEx, setAnchorEx] = useState(null);
  const openEx = Boolean(anchorEx);

  const handleExerciseClick = (exerciseObject) => {
    if (exerciseType === "exercises") {
      setExercise(exerciseObject.id);
      setTask(null);
    }
    if (exerciseType === "taskExercises") {
      setTaskExercise(exerciseObject.id);
    }
  };

  const handleClickEx = (event) => {
    setAnchorEx(event.currentTarget);
  };

  const handleCloseEx = () => {
    setAnchorEx(null);
  };

  const title =
    exerciseType === "taskExercises" ? "Task exercises" : "Exercises Library";
  const exercisesArray =
    exerciseType === "taskExercises" ? taskExercises : exercises;
  return (
    <>
      {exerciseType === "taskExercises" && (
        <>
          <MenuItem button onClick={() => setTask(null)}>
            <ListItemIcon>
              <ArrowBackIosIcon />
            </ListItemIcon>
            <ListItemText primary="Back" />
          </MenuItem>

          <Divider component="li" />

          <ListItem>
            <ListItemText primary={`Task Name`} />
            <TextField
              value={taskObject.title}
              onChange={handleTaskNameChange}
            />
          </ListItem>
          <ListItem color="primary">
            <Button color="primary" onClick={saveTask}>
              Save
            </Button>
          </ListItem>
          <Divider component="li" />
        </>
      )}
      <ListItem>
        <ListItemText primary={title} />
        <ListItemSecondaryAction>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClickEx}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEx}
            keepMounted
            open={openEx}
            onClose={handleCloseEx}
          >
            {exerciseType === "exercises" && (
              <MenuItem onClick={handleAddExerciseToTask}>
                <ListItemIcon>
                  <FileCopyIcon fontSize="small" />
                </ListItemIcon>
                Add to task
              </MenuItem>
            )}
            <MenuItem onClick={handleCopyExercise}>
              <ListItemIcon>
                <FileCopyIcon fontSize="small" />
              </ListItemIcon>
              Copy
            </MenuItem>
            <MenuItem onClick={handleDeleteExercise}>
              <ListItemIcon>
                <DeleteForeverIcon fontSize="small" />
              </ListItemIcon>
              Delete
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
      {exercisesArray && exercisesArray.length > 0 && (
        <>
          {exercisesArray.map((exerciseObject) => {
            const inset =
              !exerciseObject.answers || exerciseObject.answers.length === 0;

            return (
              <MenuItem
                button
                onClick={() => handleExerciseClick(exerciseObject)}
                key={`${exerciseObject.id}`}
                selected={exercise === exerciseObject.id}
              >
                {exerciseObject.solved === true && (
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                )}

                {exerciseObject.answers &&
                  exerciseObject.answers.length > 0 &&
                  exerciseObject.solved !== true && (
                    <ListItemIcon>
                      <CloseIcon />
                    </ListItemIcon>
                  )}
                <ListItemText
                  id={`label${exerciseObject.id}`}
                  primary={`${exerciseObject.id}`}
                  secondary={`${
                    exerciseObject.text || exerciseObject.firstText
                  }`}
                  inset={inset}
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    checked={selectedExercises[exerciseObject.id] || false}
                    inputProps={{
                      "aria-labelledby": `label${exerciseObject.id}`,
                    }}
                    onChange={(event) =>
                      setSelectedExercises({
                        ...selectedExercises,
                        [exerciseObject.id]: event.target.checked,
                      })
                    }
                  />
                </ListItemSecondaryAction>
              </MenuItem>
            );
          })}
        </>
      )}

      <Divider variant="inset" component="li" />
      <MenuItem
        key="newExercise"
        onClick={() => {
          console.log(exerciseType);
          if (exerciseType === "exercises") {
            setExercise("new");
            setTask(null);
          }
          if (exerciseType === "taskExercises") {
            setTaskExercise("new");
          }
        }}
        selected={
          exerciseType === "taskExercises"
            ? taskExercise === "new"
            : exercise === "new"
        }
      >
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary={`New Exercise`} />
      </MenuItem>
    </>
  );
};

export default RenderExercises;
