import React, { useState, useEffect } from "react";
import { getErrant } from "../../tools/nlp";
import firebase from "../../tools/firebase";
import {
  Typography,
  Grid,
  Hidden,
  List,
  ListItem,
  Divider,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Avatar,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
  Box,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cogIcon from "../../static/cog.jpeg";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CheckIcon from "@material-ui/icons/Check";

import { connect, useDispatch } from "react-redux";
import { createExerciseFromErrant } from "../../stores/actions/exercise";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../stores/actions/notification";

const db = firebase.firestore();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

function Admin(props) {
  const [chat, setChat] = useState(null);
  const [message, setMessage] = useState(null);
  const [groups, setGroups] = useState([]);
  const [chatArray, setChatArray] = useState([]);
  const [messageObject, setMessageObject] = useState({});
  const [correction, setCorrection] = useState("");

  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));

  // this will run only once
  useEffect(() => {
    const unsubscribe = db
      .collectionGroup("groups")
      .onSnapshot(function (querySnapshot) {
        let groupsArray = [];
        querySnapshot.forEach(function (doc) {
          const docData = doc.data();
          groupsArray.push({
            id: doc.id,
            name: docData.first_name
              ? `${docData.first_name} ${docData.last_name || ""}`
              : docData.title,
          });
        });
        setGroups(groupsArray);
      });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (chat) {
      const unsubscribe = db
        .collection("platform")
        .doc("telegram")
        .collection("groups")
        .doc(`${chat}`)
        .collection("messages")
        .orderBy("message_id", "desc")
        .onSnapshot(function (querySnapshot) {
          let chatsArray = [];
          querySnapshot.forEach(function (doc) {
            const docData = doc.data();
            chatsArray.push({
              id: docData.message_id,
              text: docData.text,
              response: docData.response,
              from: `${docData.from?.first_name || ""} ${
                docData.from?.last_name || ""
              }`,
              date: new Date(docData.date * 1000),
              correction: docData.correction,
            });
          });
          setChatArray(chatsArray);
        });
      return unsubscribe;
    }

    if (!chat) {
      setChatArray([]);
      return;
    }
  }, [chat]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await db
        .collection("platform")
        .doc("telegram")
        .collection("groups")
        .doc(`${chat}`)
        .collection("messages")
        .doc(`${message}`)
        .get();
      if (response.empty) {
        console.log("empty response");
        return;
      }

      const docData = response.data();
      if (docData) {
        setMessageObject({
          id: docData.message_id,
          from: `${docData.from?.first_name || ""} ${
            docData.from?.last_name || ""
          }`,
          userId: docData.from?.id || null,
          text: docData.text,
          date: docData.date ? new Date(docData.date * 1000) : new Date(),
        });
        setCorrection(
          docData.correction ? docData.correction.text : docData.text
        );
      }
    };
    if (!message || !chat) {
      setMessageObject({});
      return;
    }
    fetchData();
  }, [message, chat]);

  const renderGroups = () => {
    return (
      <Grid item xs={12} sm={6} md={4}>
        {groups.length === 0 && <CircularProgress />}
        {groups.length > 0 && (
          <List className={classes.root}>
            {groups.map((group) => {
              return (
                <React.Fragment key={group.id}>
                  <MenuItem
                    alignItems="flex-start"
                    onClick={() => {
                      setChat(group.id);
                      setMessage(null);
                    }}
                    button
                    selected={group.id === chat ? true : false}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={group.name}
                        src="/static/images/avatar/1.jpg"
                      />
                    </ListItemAvatar>
                    <ListItemText primary={group.name} />
                  </MenuItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              );
            })}
          </List>
        )}
      </Grid>
    );
  };

  const renderMessages = () => {
    return (
      <Grid item xs={12} sm={6} md={4}>
        <Paper styles={{ maxHeight: "100%", overflow: "auto" }}>
          {chatArray.length === 0 && <CircularProgress />}
          {chatArray.length > 0 && (
            <List className={classes.root} style={{ width: "100%" }}>
              <MenuItem button onClick={() => setChat(null)}>
                <ListItemIcon>
                  <ArrowBackIosIcon />
                </ListItemIcon>
                <ListItemText primary="Back" />
              </MenuItem>
              <Divider variant="inset" component="li" />
              {chatArray.map((chat) => {
                return (
                  <React.Fragment key={chat.id}>
                    <MenuItem
                      button
                      alignItems="flex-start"
                      onClick={() => setMessage(chat.id)}
                      selected={chat.id === message ? true : false}
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt={chat.from}
                          src="/static/images/avatar/1.jpg"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={chat.from}
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {`${chat.date.toLocaleString()}   `}
                            </Typography>
                            {chat.text}
                          </>
                        }
                      />
                      {chat.correction && (
                        <ListItemIcon>
                          <CheckIcon color="primary" />
                        </ListItemIcon>
                      )}
                    </MenuItem>
                    <Divider variant="inset" component="li" />
                    {chat.response && (
                      <React.Fragment key={`${chat.id}-response`}>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar alt={`Cog`} src={cogIcon} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={`Cog`}
                            secondary={
                              <React.Fragment>{chat.response}</React.Fragment>
                            }
                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              })}
            </List>
          )}
        </Paper>
      </Grid>
    );
  };

  const handleCorrection = (event) => {
    setCorrection(event.target.value);
  };

  const submitCorrection = async () => {
    const { dispatch } = props;
    const errantParams = {
      original: messageObject.text,
      correction: correction,
    };
    setMessage(null);
    const response = await getErrant(errantParams);
    if (response) {
      await db
        .collection("platform")
        .doc("telegram")
        .collection("groups")
        .doc(`${chat}`)
        .collection("messages")
        .doc(`${message}`)
        .set(
          {
            correction: {
              text: correction,
              errant: response.errors_response,
            },
          },
          { merge: true }
        );
      enqueueSnackbar({
        message: "Correction saved successfully",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
      });
      dispatch(
        createExerciseFromErrant(
          messageObject.userId,
          {
            text: messageObject.text,
            correction: correction,
          },
          response.errors_response
        )
      );
    }
    if (!response) {
      enqueueSnackbar({
        message: "An error ocurred, please try again in a few mins",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      });
    }
  };

  const renderMessageForm = () => {
    return (
      <Grid item xs={12} sm={6} md={4}>
        {!messageObject.id && <CircularProgress />}
        {messageObject.id && (
          <List
            className={classes.root}
            style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}
          >
            <MenuItem button onClick={() => setMessage(null)}>
              <ListItemIcon>
                <ArrowBackIosIcon />
              </ListItemIcon>
              <ListItemText primary="Back" />
            </MenuItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  alt={messageObject.from}
                  src="/static/images/avatar/1.jpg"
                />
              </ListItemAvatar>
              <ListItemText
                primary={messageObject.from}
                secondary={messageObject.date.toLocaleString()}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Original Text`} />
            </ListItem>
            <ListItem>
              <TextField
                disabled
                fullWidth
                multiline
                value={messageObject.text}
                variant="outlined"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Correction`} />
            </ListItem>
            <ListItem>
              <TextField
                autoFocus
                fullWidth
                multiline
                value={correction}
                onChange={handleCorrection}
                variant="outlined"
              />
            </ListItem>
            <ListItem color="primary" alignItems="center">
              <Button
                color="primary"
                onClick={submitCorrection}
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <CheckIcon />
                Submit
              </Button>
            </ListItem>
          </List>
        )}
      </Grid>
    );
  };

  return (
    <>
      {props.auth.user.admin !== true && (
        <Paper>You don't have permission to see this page</Paper>
      )}
      {props.auth.user.admin === true && (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          {!chat && <>{renderGroups()}</>}
          {chat && !message && (
            <>
              <Hidden xsDown>{renderGroups()}</Hidden>
              {renderMessages()}
            </>
          )}
          {chat && message && (
            <>
              <Hidden smDown>{renderGroups()}</Hidden>
              <Hidden xsDown>{renderMessages()}</Hidden>
              {renderMessageForm()}
            </>
          )}
        </Grid>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Admin);
