export const levels = {
  1: {
    from: 0,
    to: 499,
  },
  2: {
    from: 500,
    to: 1199,
  },
  3: {
    from: 1200,
    to: 1999,
  },
  4: {
    from: 2000,
    to: 2999,
  },
  5: {
    from: 3000,
    to: 4249,
  },
  6: {
    from: 4250,
    to: 5999,
  },
  7: {
    from: 6000,
    to: 7999,
  },
  8: {
    from: 8000,
    to: 10249,
  },
  9: {
    from: 10250,
    to: 12499,
  },
  10: {
    from: 12500,
    to: 14999,
  },
  11: {
    from: 15000,
    to: 17499,
  },
  12: {
    from: 17500,
    to: 19999,
  },
  13: {
    from: 20000,
    to: 22499,
  },
  14: {
    from: 22500,
    to: 24999,
  },
  15: {
    from: 25000,
    to: 27499,
  },
  16: {
    from: 27500,
    to: 29999,
  },
  17: {
    from: 30000,
    to: 32499,
  },
  18: {
    from: 32500,
    to: 34999,
  },
};
