import * as firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyASacGTj3zK95QXLqnWIeTarkqPBpT-AR8",
  authDomain: "cognate-ai.firebaseapp.com",
  databaseURL: "https://cognate-ai.firebaseio.com",
  projectId: "cognate-ai",
  storageBucket: "cognate-ai.appspot.com",
  messagingSenderId: "1030375257327",
  appId: "1:1030375257327:web:24b60b9484be883ea4571a",
  measurementId: "G-P0GEJ1XHLE",
};
firebase.initializeApp(config);

export default firebase;
