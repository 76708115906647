import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { loginUser, signUpUser } from "../../stores/actions/auth";
import { withStyles } from "@material-ui/styles";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import Logo from "../CustomUI/Logo";

import firebase from "../../tools/firebase";
import { TransferWithinAStationRounded } from "@material-ui/icons";
const db = firebase.firestore();

const styles = () => ({
  paper: {
    marginTop: 100,
    display: "flex",
    padding: 20,
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#f50057",
  },
  form: {
    marginTop: 1,
  },
  errorText: {
    color: "#f50057",
    marginBottom: 5,
    textAlign: "center",
  },
});

class SignUp extends Component {
  state = {
    email: "",
    password: "",
    user: {
      loading: true,
      error: false,
    },
  };

  componentDidMount() {
    const getTelegramUser = async () => {
      const telegramId = this.props.match.params.id;
      if (telegramId) {
        const response = await db
          .collection("platform")
          .doc("telegram")
          .collection("users")
          .doc(`${telegramId}`)
          .get();
        if (response.empty || !response.data()) {
          this.setState({
            user: {
              loading: false,
              error: true,
            },
          });
          return;
        }

        this.setState({
          user: {
            ...response.data(),
            loading: false,
            error: false,
          },
        });
      }
    };
    getTelegramUser();
  }

  handleEmailChange = ({ target }) => {
    this.setState({ email: target.value });
  };

  handlePasswordChange = ({ target }) => {
    this.setState({ password: target.value });
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    const { email, password } = this.state;

    dispatch(signUpUser(email, password, this.props.match.params.id));
  };

  render() {
    const { classes, signUpError, isAuthenticated } = this.props;
    const telegramId = this.props.match.params.id;
    const { user } = this.state;
    console.log(user);
    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    if (!telegramId) {
      return (
        <Container component="main" maxWidth="xs">
          <Paper className={classes.paper}>
            <Logo width="100px" letters />
            <Box>Sorry, but this is not a valid Sign Up link</Box>
          </Paper>
        </Container>
      );
    }

    if (user && user.firebaseId) {
      return (
        <Container component="main" maxWidth="xs">
          <Paper className={classes.paper}>
            <Logo width="100px" letters />
            <Box>
              Sorry, but there's already a user linked to this telegram account
            </Box>
          </Paper>
        </Container>
      );
    }

    if (user.loading) {
      return (
        <Container component="main" maxWidth="xs">
          <Paper className={classes.paper}>
            <Logo width="100px" letters />
            <Box>Loading user info</Box>
          </Paper>
        </Container>
      );
    }

    if (user.error) {
      return (
        <Container component="main" maxWidth="xs">
          <Paper className={classes.paper}>
            <Logo width="100px" letters />
            <Box>Sorry, but the user was not found</Box>
          </Paper>
        </Container>
      );
    }

    return (
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper}>
          <Logo width="100px" letters />
          <Typography component="h1" variant="h5">
            {`Hi, ${user.first_name || ""} ${user.last_name || ""}`}
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={this.handleEmailChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={this.handlePasswordChange}
          />
          {signUpError && (
            <Typography component="p" className={classes.errorText}>
              Incorrect email or password.
            </Typography>
          )}
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={this.handleSubmit}
          >
            Sign Up
          </Button>
        </Paper>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    isSigningUp: state.auth.isSigningUp,
    signUpError: state.auth.signUpError,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(SignUp));
