import orange from "@material-ui/core/colors/orange";
import green from "@material-ui/core/colors/green";

export const BACKGROUND_DARK = "#180101";
export const BACKGROUND_LIGHT = "#fff7f5";

export const PRIMARY_COLOR = "#118932";
export const SECONDARY_COLOR = "#e65820";

export const PROFILE = "#e65820";

export const ENGAGEMENT_DARK = "#260000";
export const ENGAGEMENT_LIGHT = "#FFFFFF";

export const YELLOW = "#F2C114";

export const LEVEL_PROGRESS_ACTIVE = "#37AA1A";
export const LEVEL_PROGRESS_BACKGROUND = "#481D10";

export const CONTRAST_COLOR = "#b723e0";

export const EFFORT_BACKGROUND = "rgba(169, 158, 155, 0.12)";

export const WORD_CHART_BACKGROUND = orange[400];
export const WORD_CHART_BAR = orange[50];

export const EXERCISES_CHART_BACKGROUND = green[300];
