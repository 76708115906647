import React, { useState, useEffect } from "react";
import firebase from "../../tools/firebase";
import {
  Grid,
  Hidden,
  List,
  ListItem,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Checkbox,
  ListItemSecondaryAction,
  TextField,
  Button,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { connect, useDispatch } from "react-redux";
import {
  deleteExercise,
  updateExercise,
  newExercise,
  copyExercise,
  updateTaskName,
  deleteTask,
  copyTask,
  addExercisesToTasks,
} from "../../stores/actions/exercise";
import EditExercise from "../EditExercise";
import UsersDialog from "../UsersDialog";
import TaskDialog from "../TaskDialog";
import RenderExercises from "./RenderExercises";

const db = firebase.firestore();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

function ExercisesAdmin(props) {
  const { user } = props.auth;

  const [exercises, setExercises] = useState([]);
  const [exercise, setExercise] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [task, setTask] = useState(null);
  const [anchorEx, setAnchorEx] = useState(null);
  const [taskExercise, setTaskExercise] = useState(null);
  const [taskExercises, setTaskExercises] = useState([]);
  const [taskObject, setTaskObject] = useState({});
  const [selectedExercises, setSelectedExercises] = useState({});
  const [selectedTasks, setSelectedTasks] = useState({});
  const [exerciseObject, setExerciseObject] = useState({});
  const [taskExerciseObject, setTaskExerciseObject] = useState({});
  const openEx = Boolean(anchorEx);
  const [openDialog, setOpenDialog] = useState(false);
  const [copyType, setCopyType] = useState(null);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    const unsubscribe = db
      .collection("class")
      .doc(user.class || "public")
      .collection("exerciseLibrary")
      .onSnapshot(function (querySnapshot) {
        let exercisesArray = [];
        querySnapshot.forEach(function (doc) {
          const docData = doc.data();
          exercisesArray.push({
            ...docData,
            id: doc.id,
          });
        });
        setExercises(exercisesArray);
      });
    return unsubscribe;
  }, [user]);

  useEffect(() => {
    const unsubscribe = db
      .collection("class")
      .doc(user.class || "public")
      .collection("taskLibrary")
      .onSnapshot(function (querySnapshot) {
        let taskArray = [];
        querySnapshot.forEach(function (doc) {
          const docData = doc.data();
          taskArray.push({
            ...docData,
            id: doc.id,
          });
        });
        setTasks(taskArray);
      });
    return unsubscribe;
  }, [user]);

  useEffect(() => {
    const unsubscribe = db
      .collection("class")
      .doc(user.class || "public")
      .collection("taskLibrary")
      .doc(`${task}`)
      .collection(`exercises`)
      .onSnapshot(function (querySnapshot) {
        let exercisesArray = [];
        querySnapshot.forEach(function (doc) {
          const docData = doc.data();
          exercisesArray.push({
            ...docData,
            id: doc.id,
          });
        });
        setTaskExercises(exercisesArray);
      });
    return unsubscribe;
  }, [user, task]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await db
        .collection("class")
        .doc("public")
        .collection("taskLibrary")
        .doc(`${task}`)
        .collection("exercises")
        .doc(`${taskExercise}`)
        .get();
      if (response.empty) {
        console.log("empty response");
        return;
      }

      const docData = response.data();
      if (docData) {
        setTaskExerciseObject(docData);
        return;
      }
    };
    if (taskExercise === "new") {
      setTaskExerciseObject({
        type: "FILL_IN_THE_GAP",
        firstText: "",
        secondText: "",
        hint: "",
        solution: "",
        title: "",
      });
      return;
    }
    fetchData();
  }, [user, task, taskExercise]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await db
        .collection("class")
        .doc("public")
        .collection("exerciseLibrary")
        .doc(`${exercise}`)
        .get();
      if (response.empty) {
        console.log("empty response");
        return;
      }

      const docData = response.data();
      if (docData) {
        setExerciseObject(docData);
        return;
      }
    };
    if (exercise === "new") {
      setExerciseObject({
        type: "FILL_IN_THE_GAP",
        firstText: "",
        secondText: "",
        hint: "",
        solution: "",
        title: "",
      });
      return;
    }
    fetchData();
  }, [exercise]);

  const handleClickEx = (event) => {
    setAnchorEx(event.currentTarget);
  };

  const handleCloseEx = () => {
    setAnchorEx(null);
  };

  const handleClickOpenDialog = (type) => {
    setCopyType(type);
    setOpenDialog(true);
  };

  const handleClickOpenTaskDialog = () => {
    setOpenTaskDialog(true);
  };

  const handleCloseDialog = (users) => {
    setOpenDialog(false);
    if (users) {
      if (copyType === "exercise") {
        for (const userId in users) {
          if (users[userId]) {
            for (const exerciseId in selectedExercises) {
              if (selectedExercises[exerciseId]) {
                dispatch(
                  copyExercise({
                    exerciseObject: exercises.find(
                      (obj) => obj.id === exerciseId
                    ),
                    exerciseId,
                    userId,
                  })
                );
              }
            }
          }
        }
      }
      if (copyType === "task") {
        for (const taskId in selectedTasks) {
          for (const userId in users) {
            if (!users[userId]) delete users[userId];
          }
          if (selectedTasks[taskId]) {
            dispatch(
              copyTask({
                taskObject: tasks.find((obj) => obj.id === taskId),
                taskId,
                classId: user.classId || "public",
                destinataries: Object.keys(users),
              })
            );
          }
        }
      }
    }
  };

  const handleCloseTaskDialog = (chosenTasks) => {
    setOpenTaskDialog(false);
    if (chosenTasks && selectedExercises) {
      const tasks = Object.keys(chosenTasks).filter(
        (taskId) => chosenTasks[taskId] === true
      );
      const exercises = Object.keys(selectedExercises).filter(
        (exerciseId) => selectedExercises[exerciseId] === true
      );
      if (tasks.length > 0 && exercises.length > 0) {
        dispatch(
          addExercisesToTasks({
            classId: user.classId || "public",
            tasks,
            exercises,
          })
        );
      }
    }
  };

  const RenderTasks = () => {
    return (
      <>
        <ListItem>
          <ListItemText primary="Tasks Library" />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClickEx}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEx}
              keepMounted
              open={openEx}
              onClose={handleCloseEx}
            >
              <MenuItem onClick={handleCopyTask}>
                <ListItemIcon>
                  <FileCopyIcon fontSize="small" />
                </ListItemIcon>
                Copy
              </MenuItem>
              <MenuItem onClick={handleDeleteTask}>
                <ListItemIcon>
                  <DeleteForeverIcon fontSize="small" />
                </ListItemIcon>
                Delete
              </MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider variant="inset" component="li" />
        {tasks.length > 0 && (
          <>
            {tasks.map((taskObject) => {
              return (
                <MenuItem
                  button
                  onClick={() => {
                    setTaskObject(taskObject);
                    setTask(taskObject.id);
                  }}
                  key={`${taskObject.id}`}
                  selected={task === taskObject.id}
                >
                  <ListItemText
                    id={`label${taskObject.id}`}
                    primary={`${taskObject.title}`}
                    inset
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      checked={selectedTasks[taskObject.id] || false}
                      inputProps={{
                        "aria-labelledby": `label${taskObject.id}`,
                      }}
                      onChange={(event) =>
                        setSelectedTasks({
                          ...selectedTasks,
                          [taskObject.id]: event.target.checked,
                        })
                      }
                    />
                  </ListItemSecondaryAction>
                </MenuItem>
              );
            })}
          </>
        )}

        <Divider variant="inset" component="li" />
        <MenuItem key="newTask" onClick={createNewTask}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={`New Task`} />
        </MenuItem>
      </>
    );
  };

  const createNewTask = async () => {
    const response = await db
      .collection("class")
      .doc(user.class || "public")
      .collection("taskLibrary")
      .add({
        default: false,
        title: "New Task",
      });

    setTask(response.id);
    setTaskExercise(null);
    setExercise(null);
  };

  const handleDeleteExercise = () => {
    Object.keys(selectedExercises).forEach((exerciseId) => {
      if (selectedExercises[exerciseId] === true) {
        dispatch(deleteExercise({ classId: "public", exerciseId: exerciseId }));
      }
    });
    setSelectedExercises({});
    setExercise(null);
    handleCloseEx();
  };

  const handleCopyExercise = () => {
    handleClickOpenDialog("exercise");
    handleCloseEx();
  };

  const handleAddExerciseToTask = () => {
    handleClickOpenTaskDialog();
    handleCloseEx();
  };

  const handleDeleteTask = () => {
    Object.keys(selectedTasks).forEach((taskId) => {
      if (selectedExercises[taskId] === true) {
        dispatch(deleteTask({ classId: "public", taskId: taskId }));
      }
    });
    setSelectedExercises({});
    setExercise(null);
    handleCloseEx();
  };

  const handleCopyTask = () => {
    handleClickOpenDialog("task");
    handleCloseEx();
  };

  const saveTask = () => {
    dispatch(
      updateTaskName({
        classId: user.class || "public",
        taskId: task,
        taskName: taskObject.title,
      })
    );
  };

  const handleTaskNameChange = (event) => {
    setTaskObject({
      ...taskObject,
      title: event.target.value,
    });
  };

  const handleExerciseChange = (event) => {
    setExerciseObject({
      ...exerciseObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleTaskExerciseChange = (event) => {
    setTaskExerciseObject({
      ...taskExerciseObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleUpdateExercise = () => {
    if (exercise === "new") {
      dispatch(newExercise({ classId: "public", exerciseObject }));
    }
    if (exercise !== "new") {
      dispatch(
        updateExercise({
          classId: "public",
          exerciseObject,
          exerciseId: exercise,
        })
      );
    }
    setExercise(null);
  };

  const handleUpdateTaskExercise = () => {
    if (taskExercise === "new") {
      dispatch(
        newExercise({ classId: "public", taskId: task, exerciseObject })
      );
    }
    if (taskExercise !== "new") {
      dispatch(
        updateExercise({
          classId: "public",
          exerciseObject,
          exerciseId: taskExercise,
          taskId: task,
        })
      );
    }
    setExercise(null);
  };

  const RenderFirstCol = () => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <List className={classes.root} style={{ width: "100%" }}>
          <RenderTasks />
          <Divider component="li" />
          <RenderExercises
            exerciseType="exercises"
            setExercise={setExercise}
            setTask={setTask}
            setTaskExercise={setTaskExercise}
            taskExercises={taskExercises}
            exercises={exercises}
            taskObject={taskObject}
            handleTaskNameChange={handleTaskNameChange}
            saveTask={saveTask}
            handleCopyExercise={handleCopyExercise}
            handleDeleteExercise={handleDeleteExercise}
            exercise={exercise}
            selectedExercises={selectedExercises}
            setSelectedExercises={setSelectedExercises}
            taskExercise={taskExercise}
            handleAddExerciseToTask={handleAddExerciseToTask}
          />
        </List>
      </Grid>
    );
  };

  return (
    <>
      <UsersDialog open={openDialog} onClose={handleCloseDialog} />
      <TaskDialog
        open={openTaskDialog}
        onClose={handleCloseTaskDialog}
        tasks={tasks}
      />
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        {!exercise && !task && <RenderFirstCol />}
        {(exercise || (task && !taskExercise)) && (
          <>
            <Hidden xsDown>
              <RenderFirstCol />
            </Hidden>
            {exercise && (
              <EditExercise
                exerciseObject={exerciseObject}
                handleExerciseChange={handleExerciseChange}
                handleUpdateExercise={handleUpdateExercise}
                classes={classes}
                setExercise={setExercise}
                exercise={exercise}
                key="exercise"
              />
            )}
            {task && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <List className={classes.root} style={{ width: "100%" }}>
                  <RenderExercises
                    exerciseType="taskExercises"
                    setExercise={setExercise}
                    setTask={setTask}
                    setTaskExercise={setTaskExercise}
                    taskExercises={taskExercises}
                    exercises={exercises}
                    taskObject={taskObject}
                    handleTaskNameChange={handleTaskNameChange}
                    saveTask={saveTask}
                    handleCopyExercise={handleCopyExercise}
                    handleDeleteExercise={handleDeleteExercise}
                    exercise={exercise}
                    selectedExercises={selectedExercises}
                    setSelectedExercises={setSelectedExercises}
                    taskExercise={taskExercise}
                  />
                </List>
              </Grid>
            )}
          </>
        )}
        {task && taskExercise && (
          <>
            <Hidden smDown>
              <RenderFirstCol />
            </Hidden>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <List className={classes.root} style={{ width: "100%" }}>
                <RenderExercises
                  exerciseType="taskExercises"
                  setExercise={setExercise}
                  setTask={setTask}
                  setTaskExercise={setTaskExercise}
                  taskExercises={taskExercises}
                  exercises={exercises}
                  taskObject={taskObject}
                  handleTaskNameChange={handleTaskNameChange}
                  saveTask={saveTask}
                  handleCopyExercise={handleCopyExercise}
                  handleDeleteExercise={handleDeleteExercise}
                  exercise={exercise}
                  selectedExercises={selectedExercises}
                  setSelectedExercises={setSelectedExercises}
                  taskExercise={taskExercise}
                />
              </List>
            </Grid>
            <EditExercise
              exerciseObject={taskExerciseObject}
              handleExerciseChange={handleTaskExerciseChange}
              handleUpdateExercise={handleUpdateTaskExercise}
              classes={classes}
              setExercise={setTaskExercise}
              exercise={taskExercise}
              key="exercise"
            />
          </>
        )}
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(ExercisesAdmin);
