import React from "react";
import {
  Grid,
  List,
  ListItem,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  FormGroup,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import UnnecessaryWordExercise from "./UnnecessaryWordExercise";
import MissingWordExercise from "./MissingWordExercise.jsx";
import FillInTheGapExercise from "./FillInTheGapExercise";
import {
  FILL_IN_THE_GAP,
  MISSING_WORD,
  UNNECESSARY_WORD,
} from "../../constants/exerciseTypes";

const EditExercise = (props) => {
  const {
    exerciseObject,
    handleExerciseChange,
    handleUpdateExercise,
    classes,
    setExercise,
    exercise,
  } = props;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Paper>
        {exerciseObject && exerciseObject.type && (
          <List className={classes.root} style={{ width: "100%" }}>
            <MenuItem button onClick={() => setExercise(null)}>
              <ListItemIcon>
                <ArrowBackIosIcon />
              </ListItemIcon>
              <ListItemText primary="Back" />
            </MenuItem>
            <Divider component="li" />
            <FormGroup>
              <ListItem>
                <FormControl
                  disabled={exerciseObject.solved}
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="exercise-type-label">Type</InputLabel>
                  <Select
                    labelId="exercise-type-label"
                    id="exercise-type"
                    value={exerciseObject.type}
                    label="Type"
                    disabled={exercise !== "new"}
                    onChange={handleExerciseChange}
                    name="type"
                  >
                    <MenuItem value={FILL_IN_THE_GAP}>
                      <em>Fill in the gap</em>
                    </MenuItem>
                    <MenuItem value={MISSING_WORD}>
                      <em>Missing Word</em>
                    </MenuItem>
                    <MenuItem value={UNNECESSARY_WORD}>
                      <em>Unnecessary Word</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              </ListItem>
              {exerciseObject.type === FILL_IN_THE_GAP && (
                <FillInTheGapExercise {...props} key="fill" />
              )}
              {exerciseObject.type === MISSING_WORD && (
                <MissingWordExercise {...props} key="missing" />
              )}
              {exerciseObject.type === UNNECESSARY_WORD && (
                <UnnecessaryWordExercise {...props} key="unnecessary" />
              )}
              {!exerciseObject.solved && (
                <ListItem color="primary">
                  <Button onClick={() => setExercise(null)}>Discard</Button>
                  <Button color="primary" onClick={handleUpdateExercise}>
                    Save
                  </Button>
                </ListItem>
              )}
            </FormGroup>
            {exerciseObject.answers && exerciseObject.answers.length > 0 && (
              <>
                <Divider component="li" />
                <ListItem>
                  <ListItemText primary="Answers" />
                </ListItem>
                {exerciseObject.answers.map((answer, index) => {
                  return (
                    <ListItem key={`${index}-${answer}`}>
                      <ListItemText primary={answer} />
                    </ListItem>
                  );
                })}
              </>
            )}
          </List>
        )}
      </Paper>
    </Grid>
  );
};

export default EditExercise;
